import React, { useRef } from 'react';
import { Card, Comment } from "antd";
import moment from 'moment';
import '../App.css';

const ConversationCard = ({ mensajes }) => {
    const myRef = useRef(null);

    const divStyle = {
        overflowY: 'scroll',
        border: '1px solid grey',
        //width:'500px',
        height: '380px',
        position: 'relative'
      };
      
    return (
        <Card className='buzon-container'>

            <div style={divStyle}>
                {mensajes.map((mensaje) => {

                    return <Comment
                                //actions={actions}
                        className={mensaje.Sender === 'Escuela' ? 'buzon-message-received' : 'buzon-message-sent'}
                        key={mensaje._id}
                        author={mensaje.Sender === 'Escuela' ? "Escuela" : mensaje.nombreFamilia}
                        content={ mensaje.Texto }
                        datetime={ moment(mensaje.Fecha).fromNow() }
                        />
                    })
                }
                
                <div ref={myRef}></div>
            </div>
        </Card>
    )
}
export default ConversationCard;