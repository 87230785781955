import React, { useEffect, useState } from "react";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
//import ListGroup from "react-bootstrap/ListGroup";
import Timeline from "antd/es/timeline";
import { Collapse, List, Statistic, Select } from "antd";
//import ReactSearchBox from "react-search-box";
//import NuevoMovimientoFinanciero from "./NuevoMovimientoFinanciero";
//import NuevoPago from "./NuevoPago";
import { Spin } from "antd";
import { Link, useHistory } from "react-router-dom";
import {  RightSquareOutlined, CheckCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormOutlined } from "@ant-design/icons";
import '../App.css';

function Home (props) {

      //const [SchoolData, setSchoolData] = useState (null);
      const [BitacoraData, setBitacoraData]= useState (null);
      const [CalendarioData, setCalendarioData]= useState (null);
      const [BoletinesData, setBoletinesData]= useState (null);
      //const [AlumnosData, setAlumnosData] = useState (null);
      const [GruposData, setGruposData] = useState (null);
      //TagData = useState (null),
      //const [BalanceEscuelaData, setBalanceEscuelaData] = useState (null);
      //const [showModalPagos, setShowModalPagos] = useState (false);
      const [AlumnosSearchData, setAlumnosSearchData] = useState (null);
      const [AlumnosDataAgrupados, setAlumnosDataAgrupados] = useState(null);
      const [SaludData, setSaludData] = useState (null);
      const [AlumnosDataIsLoading, setAlumnosDataIsLoading] = useState (true);
      const [CalendarioDataIsLoading, setCalendarioDataIsLoading] = useState (true);
      //const [alumnoKey, setAlumnoKey] = useState("default");
      const [BoletinesDataIsLoading, setBoletinesDataIsLoading] = useState (true);
      const [GruposDataIsLoading, setGruposDataIsLoading] = useState (true);
      const [BitacoraDataIsLoading, setBitacoraDataIsLoading] = useState (true);
      //const [BalanceEscuelaIsLoading, setBalanceEscuelaIsLoading] = useState (true);
      const [SaludDataIsLoading, setSaludDataIsLoading] = useState (true);
      const [NombresGrupos, setNombresGrupos] = useState(null);
      const history = useHistory();


  useEffect(() => {
    getAlumnosData();
    getBitacoraData();
    getCalendarioData();
    getBoletinesData();
    getGruposData();
    //getBalanceEscuelaData();
    //getSaludData();
  }, []);

  const getBitacoraData = () => {
    fetch(` https://kometaapi.azurewebsites.net/api/BitacoraSummary/` + props.idEscuela)
      .then((response) => response.json())
      .then((data) => { setBitacoraData(data); setBitacoraDataIsLoading(false)})
      .catch((error) => console.log(error));
  };

  const getCalendarioData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/calendario/` + props.idEscuela)
      .then((response) => response.json())
      .then((data) =>
        { setCalendarioData (data); setCalendarioDataIsLoading (false) }
      )
      .catch((error) => console.log(error));
  };

  const getBoletinesData = () => {
    fetch('https://kometaapi.azurewebsites.net/api/boletinesProgramados/'+props.idEscuela+'?code=a2b5r6EGhCVM7h66Wl-NDpRzcwgU0A5qhlHMFWaxa8UsAzFuirfq0g==' )
    //fetch('http://localhost:7071/api/boletinesActivos/'+ props.idEscuela)  
    .then((response) => response.json())
      .then((data) => {
        console.log("boletines", data)
        //Almacenar solo el top 5 de boletines en BoletinesData
        setBoletinesData (data.slice(0, 5)); 
        setBoletinesDataIsLoading (false) ;
      })
      .catch((error) => console.log(error));
  };

  const getSaludData = () => {
    fetch('https://kometaapi.azurewebsites.net/api/respuestasCuestionarioSalud/' + props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        //EL API regresa la lista completa de alertas, hacemos el resumen para mostrarlo en el dashboard
        const saludSummary = {
          countToday: Object.keys(data.alertasHoy).length,
          count7Days: Object.keys(data.alertasSieteDias).length,
          count14Days: Object.keys(data.alertasCatorceDias).length
        }
        setSaludData (saludSummary);  
        setSaludDataIsLoading (false);
      })
      .catch((error) => console.log(error));
  };

 /* const getBalanceEscuelaData = () => {
    fetch('https://kometaapi.azurewebsites.net/api/BalanceEscuelaSummary/' + props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        setBalanceEscuelaData (data); 
        setBalanceEscuelaIsLoading (false);
      })
      .catch((error) => console.log(error));
  };*/

  const getAlumnosData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/alumnosEscuelaAgrupados/` + props.idEscuela)
    //fetch(`http://localhost:7071/api/alumnosEscuelaAgrupados/` + props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        //Guardar la info de grupos + alumnos para usarlos al seleccionar un alumno del search box y pasar la info del seleccionado
        setAlumnosDataAgrupados(data);
        //Crear un array secundario en formato valido para el search box
        var TmpArray = [];
        data.forEach(function (AlumnoItem) {
          TmpArray.push({
            value: AlumnoItem.idAlumno,
            label: AlumnoItem.Nombre + " " + AlumnoItem.ApellidoP + " " + AlumnoItem.ApellidoM,
          });
        });

          //setAlumnosData (data);
          setAlumnosSearchData (TmpArray);
          setAlumnosDataIsLoading (false);
      })
      .catch((error) => console.log(error));
  };

  const getGruposData = () => {
    fetch( 'https://kometaapi.azurewebsites.net/api/alumnosEscuelaAgrupados/' + props.idEscuela)
    //fetch(`http://localhost:7071/api/alumnosEscuelaAgrupados/` + props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        //Leer el nombre de los grupos de todos los alumnos, y despues sacar los valores unicos
        var nombresGrupos = [...new Set(data.map(grupo => grupo.NombreGrupo))];
        setNombresGrupos (nombresGrupos); 
        setGruposData (data);  
        setGruposDataIsLoading (false);
      })
      .catch((error) => console.log(error));
  };

 /* const toggleModalPagos = () => {
    setShowModalPagos (!showModalPagos)
  };*/

  const onSelectAlumno = async (value) => {
    const editInfo = await AlumnosDataAgrupados.find((al) => al.idAlumno === value);
    //setAlumnoKey (value);
    history.push({pathname: "/alumnos", state: {alumno: value, alumnoForEdit: editInfo,idEscuela: props.idEscuela}})
    console.info(editInfo)
  };


    //const {history} = props;
    return (
      <div className="container">
        <Row>
          <Col>
            <Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>
                    Resumen de bitácora
                    <a href="/bitacora">
                      <RightSquareOutlined className="cardAction" />
                    </a>
                  </Card.Title>
                  {BitacoraDataIsLoading ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (

                    <div className="card-group">
                      <div className="card">
                        <div className="card-body">
                          <Statistic
                            value={BitacoraData.IncidentesHoy}
                            precision={0}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ExclamationCircleOutlined className="antd-icon" />}
                          />
                          <p className="card-text">Incidentes hoy</p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">

                          <Statistic
                            value={BitacoraData.Incidentes7dias}
                            precision={0}
                            valueStyle={{ color: '#1990FF' }}
                            prefix={<InfoCircleOutlined className="antd-icon" />}
                          />
                          <p className="card-text">Incidentes 7 días</p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <Statistic
                            value={BitacoraData.DiasSinIncidentes}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<CheckCircleOutlined className="antd-icon" />}
                          />
                          <p className="card-text">Días sin incidentes</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer>
              </Card>
            </Row>

            <Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>
                    Próximos eventos
                    <FormOutlined className="cardAction" />
                  </Card.Title>
                  {CalendarioDataIsLoading ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (
                    <Timeline mode="alternate" className="scrollableTimeline" style={{paddingTop: 25}}>
                      {CalendarioData.map((evento) => (
                        <Timeline.Item key={evento._id}>
                          {evento.NombreEvento} ({evento.Grupo}) -{" "}
                          {evento.Fecha}{" "}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  )}
                </Card.Body>
              </Card>
            </Row>
            {/*
              props.privilegios === 'Direccion' || props.privilegios === 'Finanzas' ? 
              <Row>
                <Card className="containerCard">
                  <Card.Body>
                    <Card.Title>
                      Balance Financiero
                      <a href="/balances"><RightSquareOutlined className="cardAction" /></a>
                      <PlusSquareOutlined className="cardAction" onClick={toggleModalPagos} />
                    </Card.Title>

                    <NuevoPago 
                      closeModalPagos={toggleModalPagos}
                      showModalPagos={showModalPagos}
                      AlumnosSearchData={AlumnosSearchData}
                      idEscuela = {props.idEscuela}
                      usuarioFirmado = {props.usuarioFirmado}
                    />

                    {BalanceEscuelaIsLoading ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <div className="card-group">
                        <div className="card">
                          <div className="card-body">
                            <Statistic
                              value={BalanceEscuelaData.AlumnosAlCorriente}
                              precision={0}
                              valueStyle={{ color: '#3f8600' }}
                              prefix={<CheckCircleOutlined className="antd-icon" />}
                            />
                            <p className="card-text">Alumnos al corriente</p>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <Statistic
                              value={BalanceEscuelaData.AlumnosBalanceNegativo}
                              precision={0}
                              valueStyle={{ color: '#cf1322' }}
                              prefix={<ExclamationCircleOutlined className="antd-icon" />}
                            />
                            <p className="card-text">Alumnos al atrasados</p>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <Statistic value={BalanceEscuelaData.BalanceActualEscuela} precision={0} />
                            <p className="card-text">Balance actual</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">Last updated 3 days ago</small>
                  </Card.Footer>
                </Card>
              </Row> : <></>
                    */}


            {/*<Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>
                    Resumen de salud
                    <a href="/healthchecks"><RightSquareOutlined className="cardAction" /></a>
                    <Button variant="link" >
                    </Button>
                  </Card.Title>

                  {SaludDataIsLoading ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (

                    <div className="card-group">
                      <div className="card">
                        <div className="card-body">
                          <Statistic
                            value={SaludData.countToday}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<CheckCircleOutlined className="antd-icon" />}
                          />
                          <p className="card-text">Alertas Hoy</p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <Statistic
                            value={SaludData.count7Days}
                            precision={0}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ExclamationCircleOutlined className="antd-icon" />}
                          />
                          <p className="card-text">7 Dias</p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <Statistic value={SaludData.count14Days} precision={0} />
                          <p className="card-text">14 Dias</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Last updated 3 days ago</small>
                </Card.Footer>
              </Card>
            </Row> */}
          </Col>

          <Col>
            <Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>
                    Buscar alumnos{" "}
                    <Link to={{ pathname: '/alumnos', state: {  idEscuela: `${props.idEscuela}` } }}><RightSquareOutlined className="cardAction" /></Link>
                    {/* <Link to={{ pathname: `/alumnos/${props.idEscuela}/#${alumnoKey}` }}><PlusSquareOutlined className="cardAction"/></Link> */}
                    {/* <Link to='/alumnos' state={{ alumno: `${alumnoKey}`}}> <PlusSquareOutlined className="cardAction"/> </Link> */}
                    {" "}
                  </Card.Title>
                  {AlumnosDataIsLoading ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (
                    /*<ReactSearchBox
                      placeholder="Nombre"
                      data={AlumnosSearchData}
                      callback={(record) => console.log(record)}
                      onSelect={(record) => { console.log(record); setState({ alumnoKey: record.key }) }}
                    />*/
                        <Select
                              showSearch  
                              options={AlumnosSearchData}
                              placeholder="Nombre del alumno"
                              optionFilterProp="children"
                              style={{width: 450}}
                              onChange={onSelectAlumno} 
                              name="alumnoBuscado" 
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }/>
                  )}
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>
                    Ultimos boletines{" "}
                    <Link to={{ pathname: "/boletines", state: { idEscuela: `${props.idEscuela}`, privilegios: "Maestro"} }} ><RightSquareOutlined className="cardAction" />{" "}</Link>
                  </Card.Title>
                  {BoletinesDataIsLoading ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (
                    <List
                      itemLayout="horizontal"
                      dataSource={BoletinesData}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            title={item.Titulo}
                            description={item.Texto}
                          />
                          <div>{item.FechaInicio}</div>
                        </List.Item>
                      )}
                    />

                  )}

                </Card.Body>
              </Card>
            </Row>

            <Row>
              <Card className="containerCard">
                <Card.Body>
                  <Card.Title>Grupos activos</Card.Title>
                  <Collapse>
                    {GruposDataIsLoading ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      NombresGrupos.map((grupo) => {
                        //seleccionar solo los alumnos del grupo que le corresponde al map
                        const alumnosGrupo = GruposData.filter(g => g.NombreGrupo === grupo)

                        //Los alumnos sin grupo, tienen '' asignado como nombre de grupo, entonces para desplegarlo bien en el  panel hay que poner manualmente un nombre
                        if (grupo === '') { grupo = "Sin grupo" }

                        return (
                          <Panel header={grupo} key={grupo}>
                            <List
                              itemLayout="horizontal"
                              dataSource={alumnosGrupo}
                              renderItem={item => (
                                <List.Item>
                                  <List.Item.Meta
                                    description={item.Nombre + ' ' + item.ApellidoP}
                                  />
                                </List.Item>
                              )}
                            />
                          </Panel>
                        );
                      })
                    )}

                  </Collapse>
                </Card.Body>
              </Card>
            </Row>

          </Col>
        </Row>
      </div>
    );
}
export default Home;

const { Panel } = Collapse;
