import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { Spin, Avatar, Space, Tag, Card, Collapse } from "antd";
import { UserOutlined, WarningOutlined, TeamOutlined, PhoneOutlined } from "@ant-design/icons";
import moment from 'moment';

const { Panel } = Collapse;

const AlumnoDetailCard = ({ alumno, phone, alumnoForEdit, SchoolPaymentPlansFormatted, gruposData, SchoolPaymentPlans }) => {
  const [incidentes, setIncidentes] = useState(true);
  const [incidentesIsLoading, setIncidentesIsLoading] = useState(true);

  useEffect(() => {
    getIncidentes();
  }, []);

  const getIncidentes = async () => {
    if (phone) {
      try {
        const response = await fetch(`https://kometaapi.azurewebsites.net/api/BitacoraFamilia/` + phone);
        const data = await response.json();
        setIncidentes(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIncidentesIsLoading(false);
      }
    } else {
      setIncidentesIsLoading(false);
    }
  };

  // Find the group for the student
  const grupo = gruposData?.find(grupo => grupo.IdsAlumnos.includes(alumno?.idAlumno));
  const assignedPaymentPlanIds = grupo?.AssignedPaymentPlanIds;

  return (
    <Card style={{ marginBottom: 35 }}>
      <Row>
        <Col md={2}>
          <Avatar size={64} icon={<UserOutlined />} />
        </Col>
        <Col md={4}>
          <h4>{alumno?.Nombre} {alumno?.ApellidoP}</h4>
        </Col>
        <Col md={2}>
          <p>Condiciones Medicas: </p>
        </Col>
        <Col md={4}>
          <p>{alumno?.CondicionesMedicas}</p>
        </Col>
      </Row>
      <br /><br />
      <Row>
        <Col md={2}>Fecha Nac:</Col>
        <Col md={4}>
          <Card size="small">
            {moment(alumno?.FechaNacimiento).format('MM-DD-YYYY')}
          </Card>
        </Col>
        <Col md={2}>Alumno desde:</Col>
        <Col md={4}>
          <Card size="small">
            {moment(alumno?.FechaInscripcion).format('MM-DD-YYYY')}
          </Card>
        </Col>
      </Row>
      <br /><br />
      <Row style={{ marginTop: 20 }}>
        <Col md={2}>Cobros programados:</Col>

        <Col md={3}>
          <p>Colegiaturas</p>
          <Space size={[0, 8]} wrap>
            {assignedPaymentPlanIds?.length > 0 ?
              assignedPaymentPlanIds.map((PlanId) => {
                let PlanInfo = SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId)
                return (<Tag color="green" key={PlanId}>{PlanInfo?.label}</Tag>)
              }) :
              <p>No hay planes de pago asignados</p>
            }
          </Space>
        </Col>

        <Col md={3}>
          <p>Extracurriculares</p>
          <Space size={[0, 8]} wrap style={{ flex: 1 }}>
            <div>
              {alumno?.AdditionalPaymentPlans?.length > 0 ?
                alumno?.AdditionalPaymentPlans.map((PlanId) => {
                  let PlanInfo = SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId)
                  return (<Tag color="blue" key={PlanId}>{PlanInfo?.label}</Tag>)
                }) :
                null
              }
            </div>
          </Space>
        </Col>

        <Col md={3}>
          <p>Descuentos</p>
          <Space size={[0, 8]} wrap>
            <div>
              {alumno?.AppliedDiscounts?.length > 0 ?
                alumno?.AppliedDiscounts.map((Discount, index) => {
                  let PlanInfo = SchoolPaymentPlans?.find(_p => _p._id === Discount.RelatedPaymentPlan)
                  //console.log(SchoolPaymentPlans)
                  return (<Tag color="orange" key={index}>{PlanInfo?.PlanName} -${Discount.Amount} </Tag>)
                }) :
                null
              }
            </div>
          </Space>
        </Col>
      </Row>
      <br /><br />
      <Row>
        <Col md={2}>Contactos: </Col>
        <Col>
          <Collapse accordion>
            {alumno?.Contactos.map((c) => (
              <Panel header={c.Nombre} key={c.Nombre}>
                <ul>
                  <li><TeamOutlined className="mr-3" /> Parentezco: {c.Parentezco}</li>
                  <li><UserOutlined className="mr-3" /> Nombre: {c.Nombre}</li>
                  <li><PhoneOutlined className="mr-3" /> Telefono: {c.Telefono}</li>
                  <li><PhoneOutlined className="mr-3" /> Carro: {c.Carro}</li>
                </ul>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
      <br /><br />
      <Row>
        {incidentesIsLoading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <Col>
            <a className="mr-4" href="/bitacora">
              <WarningOutlined />
            </a>
            {incidentes.length} Incidentes en historial
          </Col>
        )}
      </Row>
    </Card>
  );
}

export default AlumnoDetailCard;
