import React, { useState, useEffect } from 'react'
import { Result, Typography, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';


export default function ReturnUrl(props) {

    //const loginCardStyile = { height: 400, width: 400, justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column' }
    const { Paragraph, Text } = Typography;
    const location = useLocation();
    const [stripeAccountSaved, setStripeAccountSaved] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success') === 'true';
    const accountId = searchParams.get('acct');
    const schoolId = searchParams.get('sc');

    useEffect(() => {
      registerStripeAccount();
    }, []);

    const registerStripeAccount = () => {
      //Si el back respondio on un ok al linkeo de la cuenta de stripe
      if (success) {
        const requestOptions = {
          method: 'POST',
          headers: { "Accept": "application/json", 'Content-Type': 'application/json' },
          body: JSON.stringify({
            idEscuela: schoolId,
            stripeAccount: accountId,
          })
        };
  
        fetch('https://kometaapi.azurewebsites.net/api/saveStripeAccount', requestOptions)
          .then(response => {
            if (response.status !== 200) {
              message.error({
                content: 'Error al guardar cuenta Stripe',
                className: 'custom-class',
                style: {
                  marginTop: '8vh',
                }
              });
            }
            else  setStripeAccountSaved(true)
          })
          .catch(error => {
            console.error('Error al guardar cuenta stripe!', error);
            setStripeAccountSaved(false)
          })
      }
    }

    return (
        <div style={{ "marginTop": "120px" }}>
            <center>
                <Result
                    title={success?"Tu cuenta de Stripe ha sido ligada exitosamente" : "Error al ligar cuenta de stripe"}
                    subTitle= {`La cuenta generada es ${accountId}`}
                    status={stripeAccountSaved}
                >
                    <div className="desc">
                    <Paragraph>
                        <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                        >
                        Para revisar el problema puedes:
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> Enviar un correo a soporte técnico para reivsar a detalle. 
                    </Paragraph>
                    </div>
                </Result>
            </center>
        </div>
    )
}