
import React, { useState, useEffect } from "react";
//import { Tab, Nav } from "react-bootstrap";
//import { Col } from "react-bootstrap";
//import { Row } from "react-bootstrap";
import { Table, Spin, Select } from "antd";
//import { EditOutlined } from "@ant-design/icons";
import {  Button, Modal, Form, Input, DatePicker, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
//const { Option } = Select;

const Maestros = (props) => {
  const [MaestrosData, setMaestrosData] = useState([]);
  const [MaestrosDataIsLoading, setMaestrosDataIsLoading] = useState(true);
  //const [idDelete, setIdDelete] = useState(null);
  //const [Maestro, setMaestro] = useState([]);
  //const [MaestroIsLoading, setMaestroIsLoading] = useState(true);
  //const [validated, setValidated] = useState(false);
  //const [Nombre, setNombre] = useState(null);
  //const [Telefono, setTelefono] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  //const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    getMaestrosData();
  }, []);

  const handleShowAdd = () => setShowModalAdd(true);
  const handleCloseAdd = () => setShowModalAdd(false);

  const getMaestrosData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/maestrosEscuela/${props.idEscuela}`)
      .then((response) => response.json())
      .then((data) => {
        setMaestrosData(data);
        setMaestrosDataIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

 /* const getMaestro = (id) => {
    fetch(
      `https://kometaapi.azurewebsites.net/api/maestro/${id}?code=j3yLs0NE5LzTIb/tKSXai6rVmEOX7UJD9MiM05ykMdxhu/pjwppFkQ==`
    )
      .then((response) => response.json())
      .then((data) => {
        setMaestro(data);
        setMaestroIsLoading(false);
        console.log(MaestrosData);
      })
      .catch((error) => console.log(error));
    console.log("Ay tan los alumnos: ");
    console.log(MaestrosData);
    setIdDelete(id);
  };*/

  const sendMaestro = (values) => {
    values.idEscuela = props.idEscuela;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };
    console.log(requestOptions.body);
    fetch(
      `https://kometaapi.azurewebsites.net/api/maestro/?code=dC3Aqo3ZK-11qMjLmeXHHf1-oJnB8p6ja7rlUan0y6UnAzFuCyTTZA==`,
      requestOptions
    )
      .then((response) => {
        if(response.status === 304)
            message.error({
              content: 'Mail/Telefono ya existente ' + response.statusText,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              }
            });
        else
        { 
          message.success({
            content: 'Maestro guardado! ', 
            className: 'custom-class',
            style: {
              marginTop: '8vh',
            }
          });
          response.json();
        }
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => 
            message.error({
              content: 'Error '+error,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              }
            })
        );
    getMaestrosData();
    handleCloseAdd();
  };

  const deleteMaestro =  (MongoId) => {
    if (window.confirm("Estas seguro de borrar este maestro?")) {
     fetch(
        `https://kometaapi.azurewebsites.net/api/EliminarMaestro/${MongoId}`
     //     `http://localhost:7071/api/EliminarMaestro/${MongoId}`
      )
        .then((response) => {
          if (response.ok) {
            message.success({
              content: 'Maestro eliminado!',
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              }
            });
          }
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => 
            message.error({
              content: 'Error '+error,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              }
            })
        );
    }
  };

  /*const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      handleCloseAdd();
    }
  };*/

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  /*const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };*/

  const columns = [
    {
      title: 'ID',
      dataIndex: 'IdMaestro',
      key: 'IdMaestro',
    },
    {
      title: 'Nombre',
      dataIndex: 'Nombre',
      key: 'Nombre',
    },
    {
      title: 'Telefono',
      dataIndex: 'Telefono',
      key: 'Telefono',
    },
    {
      title: 'FechaEntrada',
      dataIndex: 'FechaEntrada',
      key: 'FechaEntrada',
      render: (text) => text.substring(0, 10), // Format the name
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (IdMaestro) => <a onClick={() => deleteMaestro(IdMaestro)}><DeleteOutlined style={{color: "red"}}/></a>,
    },
   /* {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (IdMaestro) => <a onClick={() => editMaestro(IdMaestro)}><EditOutlined /></a>,
    }*/
  ];

    
    return (
      <div className="container">
        <h5>Maestros</h5>

        {
              props.privilegios === 'Direccion' || props.privilegios === 'Administracion' ?

               <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                  <Button type="outline" onClick={handleShowAdd}> <PlusOutlined /> Nuevo</Button>
              </div>
                : <></>
            }

        <Table dataSource={MaestrosData} columns={columns} />


        <Modal title="Registro de Personal" open={showModalAdd} getContainer={false} destroyOnClose={true} onCancel={handleCloseAdd}
          footer={[
            <Button key="back" onClick={handleCloseAdd}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" htmlType="submit" form='registrar-maestro'>
              Guardar
            </Button>,
          ]}>
            
          <Form
            name="registrar-maestro"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ FechaEntrada: moment(new Date()), privilegios: "Maestro" }}
            onFinish={sendMaestro}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >

            <Form.Item
              label="Nombre del maestro"
              name="Nombre"
              rules={[{ required: true, message: 'Nombre del maestro con apellidos' }]}
            >
              <Input maxLength={128}/>
            </Form.Item>

            <Form.Item
              label="Telefono"
              name="Telefono"
              rules={[{ required: true, min: 10,message: 'Ingresa teléfono a 10 dígitos' }]}
            >
                <div style={{display:'flex', flexDirection: 'row', flexWrap: 'wrap', height: '100%', verticalAlign: 'center'}}>
                  <p style={{width: '20%', textAlign: 'center'}}>+52</p>
                  <Input maxLength={10} style={{width: '80%'}}/>
                </div>
            </Form.Item>

            <Form.Item
              label="Correo"
              name="Mail"
              rules={[{ required: true, type: "email",message: 'Ingresa correo electronico válido' }]}
            >
              <Input maxLength={128}/>
            </Form.Item>

            <Form.Item
              label="Fecha de entrada"
              name="FechaEntrada"
              rules={[{ required: true, message: 'Selecciona fecha de entrada' }]}
            >
              <DatePicker onChange={onChange} format={'DD/MM/YYYY'} />
            </Form.Item>

            <Form.Item
              label="Privilegios"
              name="privilegios"
              rules={[{ required: true, message: 'Selcciona nivel de privilegios' }]}
            >
              <Select
                //defaultValue="Maestro"
                options={[
                  {value:"Administracion", label: "Administracion"},
                  {value:"Finanzas", label: "Finanzas"},
                  {value:"Maestro", label: "Maestro"},
                  //{value:"User", label: "User"},
                 ]}
              // style={{ width: 120, }}
              />
            </Form.Item>



            {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Guardar maestro
              </Button>
            </Form.Item> */}

          </Form>
        </Modal>
      </div>
    );
  
}

export default Maestros;
