import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
//import {Image, CloudinaryContext} from 'cloudinary-react';


function ImageUpload (props) {

    //const [uploading, setUploading] = useState(false);
    //const [inputKey, setInputKey] = useState(Math.random().toString(36));

    const uploadButton = (
    <div>
        {/*uploading ? <LoadingOutlined/> : <PlusOutlined />*/}
        <PlusOutlined /><div className="ant-upload-text">Upload</div>
    </div>
    );

    const [imageUrl, setImageUrl] = useState(props.LogoUrl);

    const handleChange =  e=> {
            //setUploading(true);

            // Get this url from response in real world.
            getBase64(e.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
            });

            /*
            const data = new FormData();
            data.append('file', e.file.originFileObj);
            data.append('upload_preset','kometa_default');
            //setUploading(true);

            const res = await fetch("https://api.cloudinary.com/v1_1/bluepanda/image/upload",
            {
                method: 'POST',
                body: data
            })

            const uploadResult = await res.json();
            console.log(uploadResult);
            setImageUrl(uploadResult.secure_url);*/
            //props.parentOnChange(uploadResult.secure_url);
            //message.success(`${e.file.name} file uploaded successfully`);
            props.parentOnChange(e.file.originFileObj);
            //setUploading(false);
    };

    //Funcion dummy que no hace nada, pero hay que pasarle un parametro al action del upload de antd
    const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
    };

    //Convert image to base64 so it can be uploaded to MongoDB
    function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
    }


    function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
    };

    return (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? <img src={imageUrl} alt="logo" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
   
    ) // return

} //function
export default ImageUpload
