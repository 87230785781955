import React, { useState, useEffect } from "react";
import "./App.css";
import { Layout, Menu, Badge, Button, message, Popconfirm } from 'antd';
import { 
  HomeOutlined, 
  BookOutlined, 
  CalendarOutlined, 
  TeamOutlined, 
  FileTextOutlined, 
  UserOutlined, 
  ClusterOutlined, 
  DollarOutlined, 
  BarChartOutlined, 
  SettingOutlined, 
  MailOutlined,
  PoweroffOutlined 
} from '@ant-design/icons';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Bitacora from "./components/Bitacora";
import Calendario from "./components/Calendario";
import GruposUI from "./components/Grupos";
import Alumnos from "./components/AlumnosNew";
import Home  from "./components/Home2";
import Settings from "./components/Settings";
import Balances  from "./components/Balances";
import Boletines from "./components/Boletines";
import Maestros from "./components/Maestros";
import Login from './components/Login';
//import Healthchecks from "./components/Healthchecks";
import Buzon from './components/Buzon';
import Pagos from './components/Pagos';
import Reportes from './components/Reportes'; 
import "./App.css";
import ExceptionView from "./components/ExceptionView";
import LoadingView from "./components/loadingView";
import ReturnUrl from "./components/stripeSuccess";
import { signOut } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig';

const { Header, Content, Sider } = Layout;

export function App() {
  const token = localStorage.getItem('userPhone');
  const [SchoolData, setSchoolData] = useState();
  const [BuzonData, setBuzonData] = useState();
  const [planesPagoData, setPlanesPagoData] = useState();
  const [unreadBuzonMessages, setUnreadBuzonMessages] = useState();
  const [ProfileData, setProfileData] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const [gruposData, setGruposData] = useState();
  const [teacherHasData, setTeacherHasData] = useState(false);

  useEffect(() => {
    getSchoolData();
  }, []);

  const getSchoolData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/infoMaestro/${token}`)
      .then(response => {
        if (response.status === 204) setTeacherHasData(false);
        else return response.json();
      })
      .then(data => {
        setProfileData(data);
        return fetch(`https://kometaapi.azurewebsites.net/api/escuelas/${data.idEscuela}`);
      })
      .then(response => response.json())
      .then(data => {
        setSchoolData(data);
        getGruposData(data);
        getBuzonData(data);
        getPlanesPagoData(data);
        setTeacherHasData(true);
      })
      .catch(err => {
        console.error('Request failed', err);
      })
      .finally(() => setProfileLoading(false));
  };

  const getBuzonData = (schoolData) => {
    fetch(
      `https://kometaapi.azurewebsites.net/api/buzon/${schoolData.idEscuela}?code=0a-HLI759QFSyOtLy481nyhObbu3wv9zqyXAVBO54qJZAzFu_q9QcQ==`
    )
      .then((response) => response.json())
      .then((data) => {
        setBuzonData(data);
        const unreadMessages = data.filter((message) => !message.VistoPorDestinatario && message.Sender === "Familia");
        setUnreadBuzonMessages(unreadMessages);
      })
      .catch((error) => {
        console.log(error);
        message.error({
          content: 'Error leer la info del buzon: ' + error,
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      });
  };

  const getPlanesPagoData = (schoolData) => {
    fetch(`https://kometaapi.azurewebsites.net/api/PlanesPago/${schoolData.idEscuela}`)
      .then((response) => response.json())
      .then((data) => {
        setPlanesPagoData(data);
      })
      .catch((error) => {
        console.log(error);
        message.error({
          content: 'Error leer la info de planes de pago: ' + error,
          className: 'custom-class',
          style: { marginTop: '8vh' },
        });
      });
  };

  const getGruposData = (schoolData) => {
    fetch(`https://kometaapi.azurewebsites.net/api/gruposEscuela/${schoolData.idEscuela}?code=n5unGz6UROVm3nLp_msVp1erbmk4c_UeKSQF4ReEZiNSAzFuxCzDzg==`)
      .then((response) => response.json())
      .then((data) => {
        setGruposData(data);
      })
      .catch((error) => {
        console.log(error);
        message.error({
          content: 'Error leer la info de los grupos: ' + error,
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      });
  };

  const updateUnreadMessages = (readMessages) => {
    const newBuzonList = BuzonData.map((msg) => {
      if (readMessages.some((readMessage) => readMessage._id === msg._id)) {
        msg.VistoPorDestinatario = true;
      }
      return msg;
    });

    const unreadMessages = newBuzonList.filter((message) => !message.VistoPorDestinatario && message.Sender === "Familia");
    setUnreadBuzonMessages(unreadMessages);
    setBuzonData(newBuzonList);
  };

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem('userPhone');
    localStorage.removeItem('token');
    localStorage.removeItem('correo');
    window.location.href = '/';
  };

  const Navigator = () => {
    const userType = ProfileData.privilegios;

    const navItems = {
      Maestro: [
        { to: "/", label: "Home", icon: <HomeOutlined /> },
        { to: "/bitacora", label: "Bitacora", icon: <BookOutlined /> },
        { to: { pathname: "/calendario", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Maestro" } }, label: "Calendario", icon: <CalendarOutlined /> },
        { to: { pathname: '/alumnos', state: { alumno: 'default', idEscuela: SchoolData?.idEscuela, privilegios: "Maestro" } }, label: "Alumnos", icon: <TeamOutlined /> },
        { to: { pathname: "/boletines", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Maestro" } }, label: "Boletines", icon: <FileTextOutlined /> }
      ],
      Direccion: [
        { to: "/", label: "Home", icon: <HomeOutlined /> },
        { to: "/bitacora", label: "Bitacora", icon: <BookOutlined /> },
        { to: "/calendario", label: "Calendario", icon: <CalendarOutlined /> },
        { to: { pathname: '/alumnos', state: { alumno: 'default', idEscuela: SchoolData?.idEscuela } }, label: "Alumnos", icon: <TeamOutlined /> },
        { to: { pathname: "/boletines", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Maestro" } }, label: "Boletines", icon: <FileTextOutlined /> },
        { to: "/maestros", label: "Maestros", icon: <UserOutlined /> },
        { to: "/roster", label: "Grupos", icon: <ClusterOutlined /> },
        { to: { pathname: "/balances", state: { SchoolData: SchoolData } }, label: "Balances", icon: <DollarOutlined />, condition: SchoolData?.preferencias.enableFinanceModule },
        //{ to: "/healthchecks", label: "Salud", icon: <HeartOutlined />, condition: SchoolData?.preferencias.enableHealthCheck },
        { to: { pathname: "/pagos", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Direccion" } }, label: "Pagos", icon: <DollarOutlined /> },
        { to: "/reportes", label: "Reportes", icon: <BarChartOutlined /> }
      ],
      Administracion: [
        { to: "/", label: "Home", icon: <HomeOutlined /> },
        { to: "/bitacora", label: "Bitacora", icon: <BookOutlined /> },
        { to: "/calendario", label: "Calendario", icon: <CalendarOutlined /> },
        { to: { pathname: '/alumnos', state: { alumno: 'default', idEscuela: SchoolData?.idEscuela } }, label: "Alumnos", icon: <TeamOutlined /> },
        { to: "/maestros", label: "Maestros", icon: <UserOutlined /> },
        { to: { pathname: "/boletines", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Maestro" } }, label: "Boletines", icon: <FileTextOutlined /> },
        { to: "/roster", label: "Grupos", icon: <ClusterOutlined /> },
        { to: { pathname: "/balances", state: { SchoolData: SchoolData } }, label: "Balances", icon: <DollarOutlined />, condition: SchoolData?.preferencias.enableFinanceModule },
        //{ to: "/healthchecks", label: "Salud", icon: <HeartOutlined />, condition: SchoolData?.preferencias.enableHealthCheck },
        { to: { pathname: "/pagos", state: { idEscuela: SchoolData?.idEscuela, privilegios: "Direccion" } }, label: "Pagos", icon: <DollarOutlined /> },
        { to: "/reportes", label: "Reportes", icon: <BarChartOutlined /> }
      ]
    };

    const navigationItems = navItems[userType].map((item, index) => (
      item.condition !== false ? (
        <Menu.Item key={index} icon={item.icon}>
          <Link to={item.to}>{item.label}</Link>
        </Menu.Item>
      ) : null
    ));

    return (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
        {navigationItems}
        <Menu.Item key="buzon" icon={<MailOutlined />}>
          <Link to={{ pathname: '/buzon', state: { BuzonData: BuzonData } }}>
            Buzon
            <Badge count={unreadBuzonMessages?.length} overflowCount={10} style={{ marginTop: 10 }} />
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  if (!token) return <Login />;

  return (
    profileLoading ? <LoadingView /> :
      teacherHasData ?
        <Router>
          <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible>
              <div className="logo">
                <div className="escuelaHeader">
                  <span className="nombreHeader">{SchoolData?.nombre}</span>
                  <span className="tipoHeader">{ProfileData?.privilegios}</span>
                </div>
              </div>
              <Navigator />
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}>
                <div style={{ float: 'right', paddingRight: '20px', color: 'lightgrey' }}>
                  <Link to="/settings">
                    <Button type="link" icon={<SettingOutlined style={{ color: 'lightgrey' }} />}>Settings</Button>
                  </Link>
                  <Popconfirm
                    title="Are you sure you want to logout?"
                    onConfirm={logout}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" icon={<PoweroffOutlined style={{ color: 'lightgrey' }} />}>Logout</Button>
                  </Popconfirm>
                </div>
              </Header>
              <Content style={{ margin: '0 16px' }}>
                <Switch>
                  <Route path="/" exact 
                    render={(props)=>  <Home {...props} idEscuela={SchoolData.idEscuela} usuarioFirmado={ProfileData} preferenciasEscuela={SchoolData.preferencias} privilegios={ProfileData.privilegios}  />}
                  />
                  <Route path="/bitacora" 
                    render={(props) => <Bitacora {...props} idEscuela={SchoolData.idEscuela} />}
                  />
                  <Route
                    path="/calendario"
                    render={(props) => <Calendario {...props} idEscuela={SchoolData?.idEscuela} gruposData={gruposData} />}
                  />
                  <Route
                    path="/roster"
                    render={(props) => <GruposUI idEscuela={SchoolData.idEscuela} gruposData={gruposData} updateGrupos={setGruposData} />}
                  />
                  <Route
                    path="/alumnos"
                    render={(props) => <Alumnos {...props} idEscuela={SchoolData?.idEscuela} privilegios={ProfileData?.privilegios} gruposData={gruposData}/>}
                  />
                  <Route
                    path="/boletines"
                    render={(props) => <Boletines {...props} idEscuela={SchoolData?.idEscuela} privilegios={ProfileData?.privilegios} gruposData={gruposData} />}
                  />
                  <Route path="/maestros" 
                    render={(props) => <Maestros {...props} idEscuela={SchoolData?.idEscuela} privilegios={ProfileData?.privilegios}/>}
                  />
                  <Route
                    path="/buzon"
                    render={(props) => <Buzon {...props} idEscuela={SchoolData.idEscuela} BuzonData={BuzonData} updateUnreadMessagesCount={updateUnreadMessages} />} 
                  />
                  <Route 
                    path="/settings" 
                    render={(props) => <Settings {...props} SchoolData={SchoolData} PlanesPago={planesPagoData}  />} 
                  />
                  <Route
                    path="/balances"
                    render={(props) => <Balances {...props} SchoolData={SchoolData} />}
                  />
                  <Route path="/pagos" 
                    render={(props) => <Pagos {...props} SchoolData={SchoolData} PlanesPago={planesPagoData} perfilUsuario={ProfileData} />}
                  />
                  <Route 
                    path="/reportes"
                    render={(props) => <Reportes {...props} idEscuela={SchoolData.idEscuela} gruposData={gruposData}/>}
                  />
                  <Route path="/stripe-success" component={ReturnUrl} />
                  <Route component={ExceptionView} />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Router>
        : <ExceptionView />
  );
}
