import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Tabs, Form, Input, Modal, message, Badge, Select, Empty } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ConversationCard from './ConversationCard';
import '../App.css';

function Buzon(props) {
  const { BuzonData, idEscuela } = props;
  const [BuzonDataAgrupado, setBuzonDataAgrupado] = useState({});
  const [NuevoMensaje, setNuevoMensaje] = useState('');
  const [SendingMessage, setSendingMessage] = useState(false);
  const [ActiveConversationUser, setActiveConversationUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AlumnosSearchData, setAlumnosSearchData] = useState([]);
  const [alumnoKey, setAlumnoKey] = useState('');
  const [family, setFamily] = useState('');
  const [sendButtonEnabled, setSendButtonEnabled] = useState(false);

  const { TextArea } = Input;
  const myRef = useRef(null);
    const [form] = Form.useForm();

  useEffect(() => {
    getBuzonDataAgrupado();
    getAlumnosData();
  }, []);

  useEffect(() => {
    if (Object.keys(BuzonDataAgrupado).length > 0 && !ActiveConversationUser) {
      const firstConversationKey = Object.keys(BuzonDataAgrupado)[0];
      setActiveConversationUser(firstConversationKey);
    }
  }, [BuzonDataAgrupado, ActiveConversationUser]);

  const getBuzonDataAgrupado = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/buzon/${idEscuela}?code=0a-HLI759QFSyOtLy481nyhObbu3wv9zqyXAVBO54qJZAzFu_q9QcQ==`)
      .then(response => response.json())
      .then(data => {
        setBuzonDataAgrupado(groupByNombreFamilia(data, 'idAlumno'));
      })
      .catch(error => {
        console.log(error);
        message.error(`Error al leer la información del buzón: ${error}`);
      });
  };

  const groupByNombreFamilia = (data, key) => {
    return data.reduce((storage, item) => {
      const group = item['nombreFamilia'];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
  };

  const handleTabChange = (selectedkey) => {
    setActiveConversationUser(selectedkey);
    setNuevoMensaje('');
    setSendButtonEnabled(false);

    const unreadFamilydMessages = BuzonData.filter(message =>
      message.VistoPorDestinatario === false &&
      message.idFamilia === selectedkey &&
      message.Sender === 'Familia'
    );

    if (unreadFamilydMessages.length > 0) {
      marcarMensajesLeidos(unreadFamilydMessages);
      props.updateUnreadMessagesCount(unreadFamilydMessages);
      setBuzonDataAgrupado(groupByNombreFamilia(props.BuzonData, 'idAlumno'));
    }
  };

  const marcarMensajesLeidos = (mensajes) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mensajes)
    };

    if (mensajes.length > 0) {
      fetch('https://kometaapi.azurewebsites.net/api/MarcarMensajesLeidos', requestOptions)
        .then(response => {
          console.log(response.status);
          message.success('Datos grabados correctamente');
        })
        .catch(error => {
          console.error('Error al guardar!', error);
          message.error('Error al grabar los cambios');
        });
    }
  };

  const sendMessage = () => {
    setSendingMessage(true);
    const destinatario = BuzonData.filter(item => item.idFamilia === ActiveConversationUser);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idEscuela: idEscuela,
        idFamilia: ActiveConversationUser,
        Texto: NuevoMensaje,
        Sender: 'Escuela',
        nombreFamilia: destinatario[0].nombreFamilia,
        VistoPorDestinatario: false,
        Fecha: new Date().toISOString()
      })
    };

    fetch(`https://kometaapi.azurewebsites.net/api/buzon/${ActiveConversationUser}?code=0a-HLI759QFSyOtLy481nyhObbu3wv9zqyXAVBO54qJZAzFu_q9QcQ==`, requestOptions)
      .then(response => {
        message.success('Datos grabados correctamente');
      })
      .catch(error => {
        console.error('Error al guardar!', error);
        message.error('Error al grabar los cambios');
      })
      .finally(() => {
        getBuzonDataAgrupado();
        setNuevoMensaje('');
        setSendingMessage(false);
      });
  };

  const getAlumnosData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/alumnosEscuelaAgrupados/${idEscuela}`)
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(item => ({
          value: item.idFamilia,
          label: `${item.Nombre} ${item.ApellidoP} ${item.ApellidoM} (${item.NombreGrupo})`,
          key: `${item.ApellidoP} ${item.ApellidoM}`
        }));
        setAlumnosSearchData(formattedData);
      })
      .catch(error => {
        console.log(error);
        message.error('Error al obtener datos de alumnos');
      });
  };

  const startNewConversation = (values) => {
    setSendingMessage(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idEscuela: idEscuela,
        idFamilia: alumnoKey,
        Texto: values.Texto,
        Sender: 'Escuela',
        nombreFamilia: family,
        VistoPorDestinatario: false,
        Fecha: new Date().toISOString()
      })
    };

    fetch(`https://kometaapi.azurewebsites.net/api/buzon/${alumnoKey}?code=0a-HLI759QFSyOtLy481nyhObbu3wv9zqyXAVBO54qJZAzFu_q9QcQ==`, requestOptions)
      .then(response => {
        message.success('Datos grabados correctamente');
      })
      .catch(error => {
        console.error('Error al guardar!', error);
        message.error('Error al grabar los cambios');
      })
      .finally(() => {
        getBuzonDataAgrupado();
        setNuevoMensaje('');
        setSendingMessage(false);
        setIsModalVisible(false);
      });
  };

  const handleMensajeChange = e => {
    setNuevoMensaje(e.target.value);
    setSendButtonEnabled(e.target.value.trim().length > 0);
  };

  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  const isEmptyConversation = Object.keys(BuzonDataAgrupado).length === 0;

  return (
    <div className="container">
      <h5>Buzon de Mensajes</h5>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <Button className='botonNuevaConversacion' style={{ marginLeft: 'auto' }} type="primary" onClick={showModal}>
          Nueva Conversacion
        </Button>
      </div>

      <Card>
        {isEmptyConversation ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Selecciona una conversación para comenzar" />
        ) : (
          <Tabs tabPosition="left" onChange={handleTabChange} activeKey={ActiveConversationUser}>
            {Object.entries(BuzonDataAgrupado).map(([familyName, mensajes]) => (
              <Tabs.TabPane
                tab={(
                  <span>
                    {mensajes.some(msg => !msg.VistoPorDestinatario && msg.Sender === 'Familia') ? (
                      <Badge dot>
                        {familyName}
                      </Badge>
                    ) : (
                      familyName
                    )}
                  </span>
                )}
                key={mensajes[0].idFamilia}
              >
                <ConversationCard mensajes={mensajes} />
                <div style={{ display: 'flex', marginTop: 15, alignItems: 'center' }}>
                  <Form.Item style={{ flex: 1 }}>
                    <TextArea
                      onChange={handleMensajeChange}
                      value={NuevoMensaje}
                      maxLength={128}
                      placeholder='Introduce tu mensaje (máximo 128 caracteres)'
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                                            htmlType="submit"
                                            style={{ marginLeft: '10px' }}
                                            loading={SendingMessage}
                                            onClick={sendMessage}
                                            type="primary"
                                            disabled={!sendButtonEnabled}
                                          >
                                            <SendOutlined /> Enviar
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </Tabs.TabPane>
                                  ))}
                                </Tabs>
                              )}
                            </Card>
                      
                            <Modal
                              title="Nueva conversación"
                              visible={isModalVisible}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              destroyOnClose={true}
                              footer={[
                                <Button form="newConversationForm" key="submit" htmlType="submit" type="primary">
                                  Enviar
                                </Button>,
                                <Button key="cancel" onClick={handleCancel}>
                                  Cancelar
                                </Button>
                              ]}
                            >
                              <Form form={form} name="newConversationForm" onFinish={startNewConversation}>
                                <Form.Item name="Alumno" label="Alumno" rules={[{ required: true, message: 'Seleccione un alumno' }]}>
                                  <Select
                                    showSearch
                                    labelInValue
                                    options={AlumnosSearchData}
                                    placeholder="Nombre del alumno"
                                    optionFilterProp="children"
                                    onChange={record => {
                                      setAlumnoKey(record.value);
                                      setFamily(record.key);
                                    }}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </Form.Item>
                                <Form.Item name="Texto" label="Texto" rules={[{ required: true, message: 'Ingrese un mensaje' }]}>
                                  <Input />
                                </Form.Item>
                              </Form>
                            </Modal>
                          </div>
                        );
                      }
                      
                      export default Buzon;
                      