import React, { useState, useEffect } from 'react';
import { Calendar, Badge, message, Modal, Form, Input, Button, Spin, Select, TimePicker } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import './Calendario.css';

const { Option } = Select;

const Calendario = ( props ) => {
  //const [startDate] = useState(new Date());
  //const [NombreEvento, setNombreEvento] = useState("");
  //const [Comentarios, setComentarios] = useState(null);
  //const [Grupo, setGrupo] = useState("todos");
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [fechas, setFechas] = useState([]);
  const [calendarioDataIsLoading, setCalendarioDataIsLoading] = useState(true);
  const [idEvento, setIdEvento] = useState(null);
  const [nombreEvento, setNombreEvento] = useState(null);
  const [comentariosEvento, setComentariosEvento] = useState(null);
  const [fechaEvento, setFechaEvento] = useState(null);
  const [horaEvento, setHoraEvento] = useState(null);
  const [grupoEvento, setGrupoEvento] = useState(null);
  const [editing, setEditing] = useState(false);
  const [nombresGrupos, setNombresGrupos] = useState(null);

  useEffect(() => {
    //console.log(props)
    if(props == null)
        window.location.href = '/';

    getNombresGrupos();
    getCalendarioData();
  }, []);

  const dateCellRender = (value) => {
    const hoy = value.format('L');
    return (
      <ul className="events">
        {fechas.map(item => (
          item.Fecha.toString() === hoy.toString() ? (
            <li onClick={() => editCalendar(item._id, item.NombreEvento, item.Comentarios, item.Grupo, item.Fecha, item.Hora)} key={item.content}>
              <Badge status={item.type} text={item.NombreEvento} />
            </li>
          ) : null
        ))}
      </ul>
    );
  }

  const getMonthData = (value) => {
    //Leer el numero de mes, sumarle 1(ya que enero es 0 para value.month) y guardarlo en formato de 2 posiciones (04,06,10..)
    const month = value.month() +1;
    const formattedMonth = month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})

    //Buscar todos los eventos de esta escuela para este mes
    const numeroEventos=fechas.filter(evento => evento.Fecha.startsWith(formattedMonth)).length

    return numeroEventos;
  }

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
        <p>{num} Eventos programados</p>
    ) : null;
  }

  const getCalendarioData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/calendario/${props.idEscuela}`)
      .then(response => response.json())
      .then(data => {
        data.forEach(evt => {
          evt.Grupo === "todos" ? evt.type = "warning" : evt.type = "success";
        });
        setFechas(data);
        setCalendarioDataIsLoading(false);
        //console.log(fechas);
      })
      .catch(error => console.log(error));
  }

  const getNombresGrupos = () => {
    var nombresGrupos = [<Option key='Todos'>Todos los grupos</Option>];
    props.gruposData.map(item => nombresGrupos.push(<Option key={item.NombreGrupo} value={item.NombreGrupo}>{item.NombreGrupo}</Option>));
    setNombresGrupos(nombresGrupos);
  }

  const handleSelectDestinatarios = (value) => {
    console.log(`selected ${value}`);
  }

  const handleShowAdd = () => {
    setShowModalAdd(true);
    setEditing(false);
    setFechaEvento(moment());
    setNombreEvento(null);
    setComentariosEvento(null);
    setGrupoEvento("Todos los grupos");
    setHoraEvento(null);
  }

  const handleCloseAdd = () => {
    setShowModalAdd(false);
    setEditing(false);
    setFechaEvento(moment());
    setNombreEvento(null);
    setComentariosEvento(null);
    setGrupoEvento("Todos los grupos");
  }

  const editCalendar = (i, n, c, g, f, h) => {
    setEditing(true);
    setShowModalAdd(true);
    setIdEvento(i);
    setNombreEvento(n);
    setComentariosEvento(c);
    setGrupoEvento(g);
    setFechaEvento(moment(f));
    setHoraEvento(moment(h, "HH:mm"));
  }

  /*const handleSelect = (e) => {
    setGrupo(e);
    console.log(Grupo);
  }*/

  const newEvent = (values) => {
    console.log(values.Fecha, values.Hora)

    //Dado que en la forma se muestran fecha y hora por separado pero en la DB es el mismo campo es necesario hacer un merge antes de mandar el fetch

    //values.Fecha =moment(values.Fecha).local().add(moment(values.Hora).hour(), 'hours');
    const mergedDateTime = moment(values.Fecha).set({
      hour: values.Hora.hour(),
      minute: values.Hora.minute(),
      //second: timeObj.second(),
      //millisecond: timeObj.millisecond(),
    });
    values.Fecha = mergedDateTime;
    //console.log(mergedDateTime )
    values.idEscuela = props.idEscuela;
    const requestOptions = {
      method: 'POST',
      headers: { "Accept": "application/json", 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    };

    fetch(`https://kometaapi.azurewebsites.net/api/calendario/${props.idEscuela}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        getCalendarioData();
        handleCloseAdd();
      })
      .catch(error => {
        console.error('Error al guardar!', error);
      });
  }

  const updateCalendar = (values) => {
    if (nombreEvento === null || nombreEvento.length < 3) {
      window.alert('Ingresa nombre de evento correcto');
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { "Accept": "application/json", 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: idEvento,
          NombreEvento: values.NombreEvento,
          Comentarios: values.Comentarios,
          idEscuela: props.idEscuela,
          Grupo: values.Grupo,
          Fecha: values.Fecha.format('YYYY-MM-DD HH:mm:ss')
        })
      };

      fetch('https://kometaapi.azurewebsites.net/api/UpdateCalendario', requestOptions)
        .then(response => {
          if (response.status !== 200) {
            message.error({
              content: 'Error al guardar alumno',
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              }
            });
          }
        })
        .catch(error => {
          console.error('Error al guardar!', error);
        })
        .finally(() => {
          setEditing(false);
          getCalendarioData();
          handleCloseAdd();
        });
    }
  }

  const deleteEvent = () => {
    if (window.confirm("Estas seguro de borrar el registro?")) {
      if (idEvento !== null) {
        fetch(`https://kometaapi.azurewebsites.net/api/EliminarEventoCalendario/` + idEvento)
          .then(response => console.log(response))
          .catch(error => console.log(error))
          .finally(() => {
            setEditing(false);
            getCalendarioData();
            handleCloseAdd();
          });
      }
    }
  }

  /*const handleChange = (e) => {
    console.log("Fecha: " + e);
  }*/

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container">
      <h5>Calendario de eventos</h5>
      
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
          <Button type="outline" onClick={handleShowAdd}>  <PlusOutlined /> Agregar evento </Button>
      </div>

      {calendarioDataIsLoading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
          <Calendar
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          // onChange={getCalendarioData()}
          // onPanelChange={getCalendarioData()}
          // onSelect={onSelect} 
          />
        )}

      <hr />

      <Modal
        title="Nuevo Evento"
        open={showModalAdd}
        onCancel={handleCloseAdd}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCloseAdd}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" htmlType="submit" form='agregar-evento'>
            Guardar
          </Button>,
        ]}
      >
        <Form
          id="agregar-evento"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            NombreEvento: nombreEvento,
            Comentarios: comentariosEvento,
            Fecha: fechaEvento ? moment(fechaEvento) : null,
            Hora: horaEvento ? moment(horaEvento) : null,
            Grupo: grupoEvento,
          }}
          onFinish={editing ? updateCalendar : newEvent}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="NombreEvento"
            name="NombreEvento"
            rules={[{ required: true, message: 'Ingresa nombre del evento' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Comentarios"
            name="Comentarios"
            rules={[{ required: true, message: 'Ingresa comentarios' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Grupo"
            name="Grupo"
            rules={[{ required: true, message: 'Selecciona grupo' }]}
          >
            <Select
              allowClear={false}
              style={{ width: '100%' }}
              placeholder="Destinatarios"
              onChange={handleSelectDestinatarios}
              required
            >
              {nombresGrupos}
            </Select>
          </Form.Item>

          <Form.Item
            label="Fecha"
            name="Fecha"
            rules={[{ required: true, message: 'Selecciona fecha' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Hora"
            name="Hora"
            rules={[{ required: true, message: 'Selecciona hora' }]}
          >
            <TimePicker />
          </Form.Item>

          {
            editing ?
              <Button danger icon={<DeleteOutlined />} onClick={deleteEvent}> Borrar evento </Button> :
              <></>
          }

        </Form>
      </Modal>
    </div>
  );
}

export default Calendario;
