import React, { useState } from 'react';
//import Container from 'react-bootstrap/Container';
import { Switch, message, Checkbox, Table, List, Collapse, Button, Input, Form, Tooltip } from 'antd';

import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import ImageUpload from './ImageUpload';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
//import '../App.css';
//import PaySettings from './paySettings';
const { Panel } = Collapse;

function Settings(props) {

  const [schoolPhone1, setSchoolPhone1] = useState(props.SchoolData.Telefono1);
  const [schoolPhone2, setSchoolPhone2] = useState(props.SchoolData.Telefono2);
  const schoolLogo = props.SchoolData.Logo;
  const [schoolLogoObject, setSchoolLogoObject] = useState(null);
  const [schoolWeb, setSchoolWeb] = useState(props.SchoolData.Web);
  const [schoolMail, setSchoolMail] = useState(props.SchoolData.Mail);
  const [enableFinanceModule, setEnableFinanceModule] = useState(props.SchoolData.preferencias.enableFinanceModule);
  const [enableChat, setEnableChat] = useState(props.SchoolData.preferencias.enableChat);
  const [enableParentPosts, setEnableParentPosts] = useState(props.SchoolData.preferencias.enableParentPosts);
  const [enablePickAndDrop, setEnablePickAndDrop] = useState(props.SchoolData.preferencias.enablePickAndDrop);
  //Si la escuela no tiene ninguna ubicación registrada se asigna un arreglo vacio para evitar errores en el render
  const pickLocationsInitalValue = props.SchoolData.preferencias.pickLocations === null? [] : props.SchoolData.preferencias.pickLocations;
  //Si la escuela no tiene ninguna ubicación registrada se asigna un arreglo vacio para evitar errores en el render
  const planesPagoInitialValue = props.PlanesPago === null? [] : props.PlanesPago;
  const [pickLocations, setPickLocations] = useState(pickLocationsInitalValue);
  const [savedPaymentPlans, setSavedPaymentPlans] = useState(planesPagoInitialValue);
  const [newLocation, setNewLocation] = useState();
  const [newPaymentPlanName, setNewPaymentPlanName] = useState();
  const [newPaymentPlanFee, setNewPaymentPlanFee] = useState();
  const [newPaymentDay, setNewPaymentDay] = useState();
  const [SchoolLatitude, setSchoolLatitude] = useState(props.SchoolData?.Ubicacion.Latitude);
  const [SchoolLongitude, setSchoolLongitude] = useState(props.SchoolData?.Ubicacion.Longitude);
  const [newPaymentPlanAplicableMonths, setNewPaymentPlanAplicableMonths] = useState([]);
  const stripeAccount = props.SchoolData?.StripeAccountId;
  const onChangeSchoolPhone1 = event => setSchoolPhone1(event.target.value);
  const onChangeSchoolPhone2 = event => setSchoolPhone2(event.target.value);
  const onChangeWeb = event => setSchoolWeb(event.target.value);
  const onChangeMail = event => setSchoolMail(event.target.value);
  
  
  const maxPickLocations = 5;
  const maxPaymentPlan = 20;
  const checkboxOptions = [
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
  ];

  const { Column } = Table;
  //const onAddPickupLocation = event => { (pickLocations=== null || pickLocations?.length <= maxPickLocations)? addLocation(event.target.value) : message.error('Maximo 5 ubicaciones') };
  //const onChangeSchoolLogo = event => setSchoolLogo(event.target.value);

   function onEnableChat(checked) {
     setEnableChat(checked);
   }

  const linkAccount = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props.SchoolData)}

    fetch('https://kometaapi.azurewebsites.net/api/linkStripeAccount?code=YbWoxkAo2BZFqlqTeREB5hz-b9jYpP18dxxk0DpFDaVPAzFu9Oc-Bg==', requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Redirect the user to the account onboarding page
        window.location.href = data.url;
      })
      .catch(error => {
        console.error('Failed to initiate Stripe Connect process:', error);
        // Handle error or show error message to the user
      });
  };
  
  const addLocation = () => {
    if (newLocation.trim() === '') {
      message.error({
        content: 'Nombre de ubicación no puede estar vacío',
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      })
      return;
    }
    
    pickLocations.length < maxPickLocations?
        setPickLocations([...pickLocations, newLocation])
      : message.error({
        content: 'Máximo 5 ubicaciones',
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      })
   // }
    setNewLocation("");
  }

  const addPaymentPlan = () => {
    if (newPaymentPlanName.trim() === '' || newPaymentPlanFee === '') {
      message.error({
        content: 'Plan de pago no puede estar vacío',
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      })
      return;
    }
    
    savedPaymentPlans.length < maxPaymentPlan?
        //antes de insertar el nuevo plan de pagos al arreglo le agregamos el  idEscuela
        setSavedPaymentPlans([...savedPaymentPlans, {"IdEscuela":props.SchoolData.idEscuela,"PlanName":newPaymentPlanName, "BasePrice":newPaymentPlanFee, "PaymentDay": newPaymentDay, "AplicableMonths": newPaymentPlanAplicableMonths, "_id":null }])
      : message.error({
        content: 'Máximo 20 planes de pago',
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      })
   // }
    setNewPaymentPlanName("");
    setNewPaymentPlanFee("");
    setNewPaymentDay("");
    setNewPaymentPlanAplicableMonths(null)
  }

  function onEnableParentPosts(checked) {
    setEnableParentPosts(checked);
  }

  function onEnablePickAndDrop(checked) {
    setEnablePickAndDrop(checked);
  }

  function onEnableFinanceModule(checked) {
    setEnableFinanceModule(checked);
  }

  function selectImage(imgObj) {
    setSchoolLogoObject(imgObj);
  }

  function onSelectMonth(checkedValues) {
    //console.log('checked = ', checkedValues);
    setNewPaymentPlanAplicableMonths(checkedValues);
  }

  function handleDeletePickLocation(id) {
    var newArray = pickLocations.filter(item => item !== id)
    setPickLocations(newArray)
    //console.log(newArray)
  }

  function handleDeletePaymentPlan(id) {
    var newArray = savedPaymentPlans.filter(item => item !== id)
    setSavedPaymentPlans(newArray)
    //console.log(newArray)
  }

  //Capturar el valor del input para nuevos pickup location
  const onChange = (e) => {
    //console.log(e.target.value);
    setNewLocation(e.target.value)
  };

   //Capturar el valor del input para nuevos pickup location
   const onChangePaymentPlanName = (e) => {
    setNewPaymentPlanName(e.target.value)
  };

  //Capturar el valor del input para nuevos pickup location
  const onChangePaymentPlanFee = (e) => {
    setNewPaymentPlanFee(e.target.value)
  };

  //Capturar el valor del input para nuevos pickup location
  const onChangeSchoolLatitude = (e) => {
    setSchoolLatitude(e.target.value)
  };

  //Capturar el valor del input para nuevos pickup location
  const onChangeSchoolLongitude = (e) => {
    setSchoolLongitude(e.target.value)
  };

   //Capturar el valor del input para nuevos pickup location
   const onChangePaymentDay = (e) => {
    setNewPaymentDay(e.target.value)
  };


  let uploadLogo = async () => {
    //setUploading(true);

    const data = new FormData();
    data.append('file', schoolLogoObject);
    data.append('upload_preset', 'kometa_default');

    const res = await fetch("https://api.cloudinary.com/v1_1/bluepanda/image/upload",
      {
        method: 'POST',
        body: data
      })

    const uploadResult = await res.json();
    return uploadResult.secure_url;
  };

  let saveChanges = async () => {
    let logoUrl = null;

    //if schoolLogoObject is null it means logo was not changed so no  upload required, otherwise will contain obj for the new selected image
    if (schoolLogoObject !== null) {
      //Update logo on cloudinary
      logoUrl = await uploadLogo();
      //console.log('retorno ' + logoUrl);
      //setSchoolLogo(logoUrl);
    }

    const requestEscuelaOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idEscuela: props.SchoolData.idEscuela,
        Nombre: props.SchoolData.Nombre,
        Telefono1: schoolPhone1,
        Telefono2: schoolPhone2,
        Web: schoolWeb,
        Mail: schoolMail,
        Ubicacion: {Latitude: SchoolLatitude, Longitude: SchoolLongitude},
        Preferencias: {
          enableChat: enableChat,
          enableFinanceModule: enableFinanceModule,
          enableParentPosts: enableParentPosts,
          enablePickAndDrop: enablePickAndDrop,
          pickLocations: pickLocations
        },
        //logoUrl solo contiene valor si el usuario ha seleccionado un nuevo logo
        Logo: logoUrl ?? schoolLogo,
        StripeAccountId: stripeAccount
      })
    }

    const requestPlanPagoOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( savedPaymentPlans)
    }
    console.log(savedPaymentPlans);
     
      try {
        const response = await fetch('https://kometaapi.azurewebsites.net/api/escuelas/' + props.SchoolData.idEscuela, requestEscuelaOptions);
        //const SchoolUpdateResponse = response.status;
        //console.log(SchoolUpdateResponse)

        const response2 = await fetch('https://kometaapi.azurewebsites.net/api/PlanesPago', requestPlanPagoOptions)
        //const PaymentPlanUpdateResponse = response2.status;
        //console.log(PaymentPlanUpdateResponse)

        if(response.status === 200 && response2.status === 200)
        {
          message.success({
            content: 'Settings actualizados correctamente',
            className: 'custom-class',
            style: {
              marginTop: '8vh',
            },
          });
        }
      }
      catch(error) {
        console.error('Error al guardar!', error);
        message.error({
          content: 'Error al grabar los cambios',
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        })
      };
      
        //console.log('response 2', matchsData)

    /*Upload updated information to DB
    fetch('https://kometaapi.azurewebsites.net/api/escuelas/' + props.SchoolData.idEscuela, requestOptions)
      .then(response => {
        response.json();
      })
      .then(data => {
        //console.log(data);
        message.success({
          content: 'Datos grabados correctamente',
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      })
      .catch(error => {
        console.error('Error al guardar!', error);
        message.error({
          content: 'Error al grabar los cambios',
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      });*/
  }

  return (
    <div className='container'>
      <Card>
        <Card.Body>
          <Card.Title>Información básica</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Para editar contacte a soporte</Card.Subtitle>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-nombre">Nombre </InputGroup.Text>
                <FormControl
                  placeholder="Nombre de la Escuela"
                  aria-label="Nombre de la Escuela"
                  aria-describedby="basic-nombre"
                  value={props.SchoolData.Nombre}
                  readOnly={true}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-id">ID Escuela </InputGroup.Text>
                <FormControl
                  placeholder="ID de la escuela"
                  aria-label="ID"
                  aria-describedby="basic-id"
                  value={props.SchoolData.idEscuela}
                  readOnly={true}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Información de contacto</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Medios de comunicación oficiales</Card.Subtitle>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="button-telefono1">
                  Telefono primario
                </InputGroup.Text>
                <FormControl
                  aria-label="Linea telefonica 1"
                  aria-describedby="button-telefono1"
                  defaultValue={schoolPhone1}
                  onChange={onChangeSchoolPhone1}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="button-telefono2">
                  Telefono secundario
                </InputGroup.Text>
                <FormControl
                  aria-label="Linea telefonica 2"
                  aria-describedby="button-telefono2"
                  defaultValue={schoolPhone2}
                  onChange={onChangeSchoolPhone2}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="button-web">
                  Sitio web
                </InputGroup.Text>
                <FormControl
                  aria-label="Web oficial"
                  aria-describedby="Web oficial"
                  defaultValue={schoolWeb}
                  onChange={onChangeWeb}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-mail">Mail escolar</InputGroup.Text>
                <FormControl
                  placeholder="Buzon escolar"
                  aria-label="Buzon escolar"
                  aria-describedby="basic-mail"
                  defaultValue={schoolMail}
                  onChange={onChangeMail}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Preferencias</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Aplican tanto al portal como a las apps de tu subscipción</Card.Subtitle>
          <Row className="justify-content-md-center">
                        <Col xs={6}> <p>Habilitar buzón digital padres - directivos</p></Col>
                        <Col> <Switch checked={enableChat} onChange={onEnableChat}/></Col>
                        </Row>
          <Row className="justify-content-md-center">
            <Col xs={6}><p>Habilitar pagos en la app de padres</p></Col>
            <Col><Switch checked={enableFinanceModule} onChange={onEnableFinanceModule} /></Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={6}><p>Habilitar módulo avanzado de recepción y entrega de alumnos</p></Col>
            <Col><Switch checked={enablePickAndDrop} onChange={onEnablePickAndDrop} /></Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={6}><p>Habilitar módulo de educación especial</p></Col>
            <Col><Switch checked={enableParentPosts} onChange={onEnableParentPosts} /></Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col xs={6}><p>Logotipo a mostrar en el portal y las apps</p></Col>
            <Col><ImageUpload parentOnChange={selectImage} LogoUrl={schoolLogo} /></Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          ></Form.Item>
          {/* End of Pay Settings */}
        </Card.Body>
      </Card>
      <Card>
          <Card.Body>
          <Card.Title>Modulo pick & drop</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Configura puntos de entrega y recepción de alumnos</Card.Subtitle>
            <Row>
                <Col xs={6}>
                    <List
                          size="small"
                          header={<div>Ubicaciones registradas</div>}
                          dataSource={pickLocations}
                          renderItem={item => <List.Item key={item} actions={[<DeleteOutlined onClick={() => handleDeletePickLocation(item)} />]}>{item}</List.Item>}
                    />
                </Col>
                <Col>
                  <br/><br/><br/>
                  <Form name="pickLocations">
                    <Form.Item>
                      <Input.Group compact>
                        <Input
                          disabled={!enablePickAndDrop}
                          value={newLocation}
                          onChange={onChange}
                          maxLength={64}
                          name="locationName"
                          style={{ width: 'calc(100% - 50px)', }}
                          placeholder="Agregar ubicación de entrega"
                          required={true}
                        />
                        <Tooltip title="Nombre de la ubicación">
                          <Button icon={<PlusOutlined />} onClick={addLocation} />
                        </Tooltip>
                      </Input.Group>
                    </Form.Item>
                  </Form>

                </Col>
              </Row>
              <hr/>
              Latitud y longitud para ubicación del colegio
              <Row>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="button-web">
                        Latitud
                      </InputGroup.Text>
                      <FormControl
                        aria-label="Latitud de la ubicación"
                        aria-describedby="Latitud"
                        defaultValue={SchoolLatitude}
                        onChange={onChangeSchoolLatitude}
                      />
                    </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-mail">Longitud</InputGroup.Text>
                    <FormControl
                      aria-label="Longitud de la ubicación"
                      aria-describedby="Longitud"
                      defaultValue={SchoolLongitude}
                      onChange={onChangeSchoolLongitude}
                    />
                  </InputGroup>
                </Col>
              </Row>
          </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Configuración de cuenta bancaria</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Configura la cuenta donde recibirás los pagos de tus alumnos</Card.Subtitle>
          <Row>
              <InputGroup className="mb-3">
             
                       {  stripeAccount == undefined? 
                              <div>
                                    <Tooltip title="Inicia al proceso de enlace de tu cuenta">
                                        <Button icon={<PlusOutlined />}  onClick={linkAccount} > Enlazar cuenta </Button>
                                    </Tooltip>
                              </div> 
                            :
                            <div>
                                   <InputGroup className="mb-3">
                                  <InputGroup.Text id="button-web">
                                    Cuenta stripe
                                  </InputGroup.Text>
                                  <FormControl
                                    aria-label="Cuenta stripe"
                                    aria-describedby="Cuenta stripe"
                                    defaultValue={stripeAccount}
                                    readOnly
                                  />
                                </InputGroup>

                            </div>
                      }
               </InputGroup>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Configuración de planes de pago</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Define las diferentes colegiaturas de la escuela</Card.Subtitle>

              <Row style={{flex: 1, margin: 10}}>
                    <h6>Planes registrados</h6>
                  <Table dataSource={savedPaymentPlans} style={{width: "95%"}}>
                      <Column title="Plan" dataIndex="PlanName" key="PlanName" />
                      <Column title="Mensualidad" dataIndex="BasePrice" key="BasePrice" />
                      <Column title="Dia de corte" dataIndex="PaymentDay" key="PaymentDay" />
                      <Column title="Meses x aplicar" dataIndex="AplicableMonths" key="AplicableMonths" render={(aplicableMonths) => aplicableMonths.join(', ')} />
                      <Column title="Action" key="action"
                          render={(text, item) => ( <DeleteOutlined onClick={() => handleDeletePaymentPlan(item)} />)}
                        />
                    </Table>

              </Row>
              <Collapse accordion>
                <Panel header="Registrar nuevo plan de pago " key="1">
                <Row>
                  <div style={{margin: 10, flex: 1, flexDirection:"column"}}>
                      <Form name="paymentPlans" style={styles.paymentPlanForm}>

                          <InputGroup>
                            <Form.Item label="Nombre del plan">
                                <Tooltip title="Como aparecerá en los estados de cuenta de las familias">
                                    <Input
                                      //disabled={!enablePickAndDrop}
                                      value={newPaymentPlanName}
                                      onChange={onChangePaymentPlanName}
                                      name="paymentPlanName"
                                      placeholder="Mensualidad kinder 1"
                                      //style={{ width: 'calc(45% - 30px)', }}
                                      required={true}
                                      maxLength={48}
                                    />
                                </Tooltip>
                            </Form.Item>

                            <Form.Item label="Mensualidad" style={{marginLeft: 20}}>
                              <Tooltip title="Cantidad a cobrar mensualmente (en pesos)">
                                      <Input
                                      //disabled={!enablePickAndDrop}
                                      value={newPaymentPlanFee}
                                      onChange={onChangePaymentPlanFee}
                                      name="paymentPlanFee"
                                      placeholder="Ej 4500"
                                      //style={{ width: 'calc(35% - 30px)', marginLeft: 5}}
                                      required={true}
                                      type='number'
                                      max={100000}
                                    />
                                </Tooltip>
                              </Form.Item>
                              

                              <Form.Item label="Dia de corte" style={{marginLeft: 20}}>
                                <Tooltip title="Fecha limite de pago">
                                    <Input
                                      //disabled={!enablePickAndDrop}
                                      value={newPaymentDay}
                                      onChange={onChangePaymentDay}
                                      name="newPaymentDay"
                                      placeholder="ej 5"
                                      //style={{ width: 'calc(20%)', marginLeft: 5}}
                                      required={true}
                                      type='number'
                                      max={31}
                                    />
                                  </Tooltip>
                                </Form.Item>

                                <p>¿En que meses se debe aplicar el cobro?</p>
                                <Checkbox.Group options={checkboxOptions} onChange={onSelectMonth} style={{width: "90%", margin: 20}}/>

                                </InputGroup>
                                      <Tooltip title="Agregar plan">
                                        <Button icon={<PlusOutlined />} onClick={addPaymentPlan} > Agregar plan </Button>
                                      </Tooltip>

                            
                          </Form>
                      </div>
                </Row>
                </Panel>
              </Collapse>
        </Card.Body>
      </Card>

      <Row>
        <Button type='primary' style={styles.settingsSubmit} onClick={saveChanges} >Guardar cambios</Button>
      </Row>
      {/* <Card>
      Independent Payment Settings
        <PaySettings />
      </Card> */}


    </div>
  ) // return
} //function
export default Settings

const styles = {
  settingsSubmit: {
    marginTop: 25,
    marginBottom: 20,
    fontWeight: 1.5,
    lineHeight: 1.5,
    marginLeft: "85%"
  },
  paymentPlanForm : {
    padding: 24,
    background: "#fbfbfb",
    border: '1px solid #d9d9d9',
    borderRadius: 6,
  }
};