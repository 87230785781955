import React, {  useEffect, useState } from 'react';
import { Modal, Form, Input, Button, DatePicker, Spin,message } from 'antd';
import { Table, Tag, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';

function Boletines (props){

    const [boletinesData, setBoletinesData] = useState(null);
    const [nombresGrupos, setNombresGrupos] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState();
    const [showModal, setShowModal] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [destinatarios, setDesinatarios] = useState(null);
    const [fechaInicioBoletin, setFechaInicioBoletin] = useState(new Date().toISOString().split("T")[0]);
    const [fechaFinBoletin, setFechaFinBoletin] = useState(new Date().toISOString().split("T")[0]);
    
    //Required for the Select component from antd, to populate destinatarios select
    const { Option } = Select;
    //Requerida la referencia para asociar con la forma del modal y poder resetear los campos cada que se genere un nuevo boletin
    const formRef = React.createRef();

    const onChangeTitulo = event => setTitulo(event.target.value);
    const onChangeMensaje = event => setMensaje(event.target.value);
    const onChangeFechaInicioBoletin = (date, dateString) => setFechaInicioBoletin(dateString);
    const onChangeFechaFinBoletin = (date, dateString) => setFechaFinBoletin(dateString);
    
    const handleClose = () => {
            setShowModal(false)
      };

      const handleNuevo = () => {
        setShowModal(true)
        };

    const resetModal = () => {
      formRef.current.resetFields();
      setShowModal(false);
    }

      let saveBoletin = async () => {
        console.log('save' + destinatarios);
                const requestOptions = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                      IdEscuela: props.idEscuela,
                      Titulo: titulo,
                      Texto: mensaje,
                      FechaInicio: fechaInicioBoletin,
                      FechaFin:fechaFinBoletin,
                      Destinatarios: destinatarios
                    })
                };

                //console.log('save ' + requestOptions.body);
                //Upload updated information to DB
                fetch('https://kometaapi.azurewebsites.net/api/boletinesActivos/'+props.idEscuela, requestOptions)
                //fetch('http://localhost:7071/api/boletinesActivos/'+props.idEscuela, requestOptions)
                .then(response => {
                      if(response.status === 200)
                      {
                        //console.log(response);
                        message.success({
                          content: 'Datos grabados correctamente',
                          className: 'custom-class',
                          style: {
                            marginTop: '8vh',
                          },
                        });
                      }
                      else{
                        message.error({
                          content: 'Error al grabar los cambios',
                          className: 'custom-class',
                          style: {
                            marginTop: '8vh',
                          },
                        });
                      }
                   })
                   .catch(error => {
                       console.error('Error al guardar!', error);
                       message.error({
                        content: 'Error al grabar los cambios',
                        className: 'custom-class',
                        style: {
                          marginTop: '8vh',
                        },
                      });
                   }).finally(() => {
                    getBoletinesData();
                    resetModal();
                 });
        }

    const columns = [
        {
          title: 'Titulo',
          dataIndex: 'Titulo',
        //  key: 'Titulo',
          render: text => <p>{text}</p>,
        },
        {
          title: 'Detalle',
          dataIndex: 'Texto',
        },
        {
          title: 'Fecha Inicio',
          dataIndex: 'FechaInicio',
        },
        {
          title: 'Fecha Fin',
          dataIndex: 'FechaFin',
        },
        {
            title: 'Destinatarios',
            dataIndex: 'Destinatarios',
            render: Destinatarios => (
              <>
                {Destinatarios.map(tag => {
                  let color = tag === 'Todos' ? 'geekblue' : 'green';
                  return (
                    <Tag color={color} key={tag}>
                      {tag.toUpperCase()}
                    </Tag>
                  );
                })}
              </>
            ),
          },
      ];

      const onSelectChange = selectedRowKeys => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys( selectedRowKeys );
      };

      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

      function handleSelectDestinatarios(value) {
        //console.log(`selected ${value}`);
        setDesinatarios(value);
      }

      async function deleteBoletines(){
            console.log('elementos a borrar: '+ selectedRowKeys);
        
            //Armar el request para el fetch
            const requestOptions = {
              method: "POST",
              headers: { 'Content-Type': 'application/json' },
              body: selectedRowKeys
            };
            console.log("body" + requestOptions.body);

            //Ejecutar el delete
            await fetch('https://kometaapi.azurewebsites.net/api/EliminarBoletines/', requestOptions)
            //const result = await fetch('http://localhost:7071/api/EliminarBoletines/',requestOptions)
              .then((response) => {
                if(response.status === 200)
                {
                  message.success({
                    content: 'Boletines borrados exitosamente: ',
                    className: 'custom-class',
                    style: {
                      marginTop: '8vh',
                    },
                  });
                }

              })
              .catch((error) => {
                
                  console.log(error);
                  message.error({
                    content: 'Error al grabar los cambios: ' + error,
                    className: 'custom-class',
                    style: {
                      marginTop: '8vh',
                    },
                  });
              }).finally(() => {
                //filtrar todos los boletine que se seleccionaron y removerlos de la variable de state para actualizar la tabla mostrada y evitar una llamada al api
                const updatedBoletinesData = boletinesData.filter((boletin) => !selectedRowKeys.includes(boletin.key));           
                setBoletinesData(updatedBoletinesData);
              });   
      };


     const getBoletinesData = () => {
        fetch('https://kometaapi.azurewebsites.net/api/boletinesProgramados/'+props.idEscuela+'?code=a2b5r6EGhCVM7h66Wl-NDpRzcwgU0A5qhlHMFWaxa8UsAzFuirfq0g==')
          .then((response) => response.json())
          .then((data) => {
              setBoletinesData(data);
          })
          .catch((error) => {            
            console.log(error);
            message.error({
              content: 'Error al leer boletines: ' + error,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              },
            });
        }).finally(() => {
            setDataLoading(false);
        });
      };

      const getNombresGrupos = () => {

       // fetch('https://kometaapi.azurewebsites.net/api/gruposEscuela/'+props.SchoolData.idEscuela+'?code=a71QAfrPY4nqZsT5vIMz5jmxWQGjtVydvFCX3NUQP6ZFM2iUMvUzQw==')
       //   .then((response) => response.json())
       //   .then((data) => {
              //Inicializar un arreglo temporal con el valor por defalt de todos los grupos, este arreglo se usa para 
              //llenar las opciones del select con los nombres de los grupos. Pero se requiere pq hay que filtrar la respuesta de la API
              var nombresGrupos = [<Option key='Todos'>Todos los grupos</Option>];
              props.gruposData.map(item => nombresGrupos.push(<Option key={item.NombreGrupo} value={item.NombreGrupo}>{item.NombreGrupo}</Option>));
              setNombresGrupos(nombresGrupos);
        //  })
        /*  .catch((error) => {
                
            console.log(error);
            message.error({
              content: 'Error leer la info de los grupos: ' + error,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              },
            });
        })*/
      };

      useEffect(() => {
          getNombresGrupos();
          getBoletinesData();
          //setLoading(false)
      // eslint-disable-next-line
      }, []);

      

    return (
      <div className="container">

            <h5>Comunicados programados</h5>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button type="outline" danger onClick={deleteBoletines}> <DeleteOutlined /> Eliminar</Button>
                <Button type="outline" onClick={handleNuevo}> <PlusOutlined /> Nuevo</Button>
                
            </div>

            {dataLoading ? (
                        <div className="spinner">
                          <Spin />
                        </div>  
                      ) : (
                            <Table 
                                rowSelection={rowSelection}
                                columns={columns} 
                                dataSource={boletinesData}
                                style={{width: "100%"}} />
                      )}


            <Modal title="Nuevo Boletin" open={showModal} onOk={saveBoletin} getContainer={false} destroyOnClose={true} onCancel={handleClose}
                    footer={[
                        <Button form="newBoletinModal" key="submit" htmlType="submit">
                            Guardar
                        </Button>,
                         <Button form="newBoletinModal" onClick={handleClose}>
                            Cancelar
                        </Button>
                        
                        ]}
                    >
            {/* Modal */}
                    <Form
                        name="basic"
                        id="newBoletinModal"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        ref={formRef}
                        onFinish={saveBoletin}
                        autoComplete="off"
                        >
                        <Form.Item
                            label="Titulo"
                            name="titulo"
                            
                            rules={[{required: true,message: 'Ingresa el titulo'}]}
                        >
                            <Input onChange={onChangeTitulo}/>
                        </Form.Item>

                        <Form.Item
                            label="Mensaje"
                            name="mensaje"
                            rules={[
                            {
                                required: true,
                                message: 'Ingresa el mensaje',
                            },
                            ]}
                        >
                            <Input onChange={onChangeMensaje}/>
                        </Form.Item>

                        <Form.Item
                            label="Destinatarios"
                            name="destinatarios"
                            rules={[
                            {
                                required: true,
                                message: 'Selecciona destinatarios',
                            },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear={false}
                                style={{ width: '100%' }}
                                placeholder="Destinatarios"
                                onChange={handleSelectDestinatarios}
                                required
                                //value={destinatarios}

                                >
                                {nombresGrupos}
                            </Select>
                        </Form.Item>

                            <Form.Item  
                                label="Fecha de inicio"
                                name="fechaInicioBoletin"
                                rules={[
                                {
                                    required: true,
                                    message: 'Seleccionar fecha de inicio',
                                },
                                ]}
                            >
                                <DatePicker onChange={onChangeFechaInicioBoletin} value={fechaInicioBoletin} format={'DD/MM/YYYY'} />
                            </Form.Item>
                            <Form.Item  
                                label="Fecha fin"
                                name="fechaFinBoletin"
                                rules={[
                                {
                                    required: true,
                                    message: 'Seleccionar fecha final',
                
                                },
                                {
                                  validator(_, value) {
                                    //formatear las fechas para evitar incosistencias de moment en diferentes navegadores
                                    const formattedFechaFin = moment(value, 'DD/MM/YYYY', true); // The 'true' argument enables strict parsing
                                    const formattedFechaInicio =  moment(fechaInicioBoletin, 'DD/MM/YYYY', true);

                                    setFechaInicioBoletin(formattedFechaInicio);
                                    setFechaFinBoletin(formattedFechaFin);

                                    if (formattedFechaFin.isValid() && formattedFechaFin.isAfter(formattedFechaInicio)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Fecha fin debe ser mayor a inicio');
                                  },
                                },
                                ]}
                            >
                                <DatePicker onChange={onChangeFechaFinBoletin} value={fechaFinBoletin} format={'DD/MM/YYYY'} />
                            </Form.Item>

                        </Form>
                            
                </Modal>
        </div>
    )

}
export default Boletines