import React, { useState, useEffect, useRef } from 'react';
import { Table, Select, DatePicker, Space, Button } from 'antd';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Reportes = (props) => {
  const [reportType, setReportType] = useState('semanal');
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'week').startOf('day'), moment().endOf('day')]);
  const [group, setGroup] = useState('Todos');
  const [BitacoraData, setBitacoraData] = useState([]);
  const [BitacoraDataIsLoading, setBitacoraDataIsLoading] = useState(true);
  const componentRef = useRef();

  const getBitacoraData = async () => {
    try {
      const response = await fetch(
        `https://kometaapi.azurewebsites.net/api/BitacoraEscuela/${props.idEscuela}`
      );
      const data = await response.json();
      const formattedData = data.map((evt) => ({
        key: evt._id,
        Alumno: `${evt.Nombre} ${evt.ApellidoP} ${evt.ApellidoM}`,
        Descripcion: evt.Descripcion,
        Fecha: moment(evt.Fecha).format('YYYY-MM-DD'),
        idAlumno: evt.idAlumno,
      }));
      setBitacoraData(formattedData);
      setBitacoraDataIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBitacoraData();
  }, []);

  const handleReportChange = value => {
    setReportType(value);
  };

  const handleDateChange = dates => {
    setDateRange(dates);
  };

  const handleGroupChange = value => {
    setGroup(value);
  };

  const getFilteredData = () => {
    let filteredData = BitacoraData;

    if (group !== 'Todos') {
      const selectedGroup = props.gruposData.find(grupo => grupo.NombreGrupo === group);
      if (selectedGroup) {
        const { IdsAlumnos } = selectedGroup;
        filteredData = filteredData.filter(item => IdsAlumnos.includes(item.idAlumno));
      }
    }

    return filteredData.filter(item => {
      const itemDate = moment(item.Fecha);
      return itemDate.isBetween(dateRange[0], dateRange[1], 'days', '[]');
    });
  };

  const columns = [
    {
      title: 'Alumno',
      dataIndex: 'Alumno',
      key: 'Alumno',
    },
    {
      title: 'Descripcion',
      dataIndex: 'Descripcion',
      key: 'Descripcion',
    },
    {
      title: 'Fecha',
      dataIndex: 'Fecha',
      key: 'Fecha',
    },
  ];

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Space direction="horizontal" size="large">
          <Select defaultValue="semanal" style={{ width: 200 }} onChange={handleReportChange}>
            <Option value="semanal">Reporte semanal por familia</Option>
            <Option value="incidentes">Reporte de incidentes</Option>
            <Option value="grupo">Reportes por grupo</Option>
            <Option value="pagos">Reporte de pagos</Option>
          </Select>
          <RangePicker
            defaultValue={dateRange}
            format="YYYY-MM-DD"
            onChange={handleDateChange}
          />
          <Select defaultValue="Todos" style={{ width: 200 }} onChange={handleGroupChange}>
            <Option value="Todos">Todos</Option>
            {props.gruposData.map(grupo => (
              <Option key={grupo.NombreGrupo} value={grupo.NombreGrupo}>
                {grupo.NombreGrupo}
              </Option>
            ))}
          </Select>
        </Space>
        <ReactToPrint
          trigger={() => <Button type="primary">Print Report</Button>}
          content={() => componentRef.current}
          pageStyle="@page { margin: 20mm; }"
        />
        <div ref={componentRef}>
          <h1>{reportType === 'semanal' ? 'Reporte semanal por familia' : ''}</h1>
          <Table columns={columns} dataSource={getFilteredData()} pagination={false} loading={BitacoraDataIsLoading} />
        </div>
      </Space>
    </div>
  );
};

export default Reportes;
