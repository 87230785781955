import React, {  useEffect, useState } from 'react';
//import Container from 'react-bootstrap/Container';
import { Row, Col,  Card, Space, Statistic, Table, Spin, message} from 'antd'
//import { DollarOutlined } from '@ant-design/icons'
//const { Option } = Select;
import moment from 'moment';

const Balances = (props) => {

    //const [AlumnosData, setAlumnosData] = useState(null);
    //const [AlumnosDataIsLoading, setAlumnosDataIsLoading] = useState(true);
    const [BalancesData, setBalancesData] = useState(null);
    const [BalancesDataIsLoading, setBalancesDataIsLoading] = useState(true);
    const [TransaccionesData, setTransaccionesData] = useState(null);
    //const [AlumnosDataIsLoading, setAlumnosDataIsLoading] = useState(true);

    const tableColumns = [
        {
            //var t = new Date( 1370001284000 );
//var formatted = moment(t).format("dd.mm.yyyy hh:MM:ss");
            title: 'Fecha',
            dataIndex: 'created',
            key: 'created',
            //created is a time stamp in miliseconds so we multiply by 1000 to convert to seconds and display correct date
            render: (created) => { return (<p>{moment(created* 1000).format('DD-MM-YYYY')}</p>)},
        },
        {
            title: 'Cantidad',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => { return (<p>{(amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }</p>)},
        },
        {
            title: 'Concepto',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'ID Transaccion',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
    ];




     const getBalanceData = () => {
        //console.log(props.SchoolData)
        fetch('https://kometaapi.azurewebsites.net/api/GetSchoolBalance/'+ props.SchoolData.StripeAccountId)
          .then((response) => response.json())
          .then((data) => {
                console.log(data)
                setBalancesData(data.balance);
                setTransaccionesData(data.transactions);
          })
          .catch((error) => {            
            console.log(error);
            message.error({
              content: 'Error al leer balance escuela: ' + error,
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              },
            });
        }).finally(() => {
            setBalancesDataIsLoading(false);
        });
      };

     

      useEffect(() => {
        getBalanceData();
      }, []);

      

    return (
        <div className="container">
                <h5>Pagos</h5>

                        <Row style={{flex: 1}}>
                                {BalancesDataIsLoading ? (
                                    <div className="spinner">
                                        <Spin />
                                    </div>
                                ) : (
                                    <div style={{ flex:1, backgroundColor: "#ECECEC",}}>
                                                <Row style={{  marginBottom: 40, marginTop: 40,  justifyContent:"center"}}>
                                                    <Col span={8} style={{marginRight: 30}}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                            title="Saldo disponible"
                                                            value={(BalancesData.available[0].amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                            precision={2}
                                                            valueStyle={{ color: '#3f8600' }}
                                                            prefix={"$"}
                                                            suffix={BalancesData.pending[0].currency}
                                                            />
                                                        </Card>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                            title="Pendente"
                                                            value={(BalancesData.pending[0].amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} 
                                                            precision={2}
                                                            valueStyle={{ color: '#3f8600' }}
                                                            prefix="$"
                                                            suffix={BalancesData.pending[0].currency}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                  </div>
                                )}
                        </Row>
  
                <Row justify="space-around" style={{marginTop: 25}}>
                    <Col>
                        <Card>
                            <h5>Resumen de movimientos</h5>
                            <Table dataSource={TransaccionesData?.data} columns={tableColumns} />

                        </Card>
                    </Col>

                </Row>
            </div >     
    )

}
export default Balances