import React from 'react';
import { Form, Input, Button, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Space, Modal } from "antd";

function NuevoAlumno (props){

    const formRef = React.createRef();
    //listItems = [];
    //infoContactos = [{ Parentezco: "", Nombre: "", Telefono: "" }];

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (

    <Modal title="Agregar Alumno" open={props.Visible} getContainer={false} onCancel={props.handleCloseAdd}
          width={650}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={props.handleCloseAdd}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" htmlType="submit" form="registrar_alumno">
              Guardar
            </Button>,
          ]}>

          <h5>Datos del alumno</h5>

          <Form
            ref={formRef}
            name="registrar_alumno"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ Balance: 0, Contactos: [''] }}
            onFinish={props.sendAlumno}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Nombre"
              name="Nombre"
              rules={[{ required: true, message: 'Ingresa nombre del alumno' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Apellido Paterno"
              name="ApellidoP"
              rules={[{ required: true, message: 'Ingresa apellido paterno del alumno' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Apellido Materno"
              name="ApellidoM"
              rules={[{ required: true, message: 'Ingresa apellido materno del alumno' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Condiciones Medicas"
              name="CondicionesMedicas"
              rules={[{ required: true, message: 'Ingresa condiciones medicas del alumno' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Balance"
              name="Balance"
              rules={[{ required: true, message: 'Ingresa balance' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Horario"
              name="Horario"
              rules={[{ required: true, message: 'Ingresa horario' }]}
            >
              <Input />
            </Form.Item>
             <Form.Item
                  label="Fecha de Nacimiento"
                  name="FechaNacimiento"
                  rules={[{ required: true, message: 'Selecciona fecha de nacimiento del alumno' }]}
                >
                  <DatePicker />
                </Form.Item>

                  <Form.Item
                    label="Fecha de Inscripcion"
                    name="FechaInscripcion"
                    rules={[{ required: true, message: 'Selecciona fecha de inscripcion del alumno' }]}
                  >
                    <DatePicker />
                  </Form.Item>
            <Form.Item
              label="Contactos"
            // rules={[{ required: true, message: 'Por favor ingresa contactos' }]}
            >
              <Form.List name="Contactos"
                rules={[
                  {
                    validator: async (_, contactos) => {
                      if (!contactos || contactos.length < 1) {
                        return Promise.reject(new Error('Ingresa al menos un contacto'));
                      }
                    },
                  },
                ]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 4 }} align='start'>
                        <Form.Item
                          {...restField}
                          name={[name, 'Parentezco']}
                          rules={[{ required: true, message: 'Ingresa Parentezco' }]}
                        >
                          <Input placeholder="Parentezco" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'Nombre']}
                          rules={[{ required: true, message: 'Ingresa el nombre del familiar' }]}
                        >
                          <Input placeholder="Nombre" nostyle="true" />
                        </Form.Item>


                        <Form.Item
                          {...restField}
                          name={[name, 'Telefono']}
                          rules={[{ required: true, message: 'Ingresa telefono' }]}
                        >
                          <Input style={{ width: '100%' }} /> 
                           {/*<PhoneInput /> */}
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'Carro']}
                          rules={[{ required: true, message: 'Ingresa carro' }]}
                        >
                          <Input placeholder="Carro" />
                        </Form.Item>
                        {fields.length > 1 && <MinusCircleOutlined onClick={() => { remove(name) }} />}
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={fields.length > 3} >
                        Agregar Contacto
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>

            {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item> */}
          </Form>
        </Modal>
)}
export default NuevoAlumno