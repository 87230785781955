import React, { Component } from "react";
import {  Button, Input, Modal, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx'
import './AlumnosBulk.css';
import AlumnosTemplate from '../assets/Alumnos.xlsx';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
};

export class AlumnosBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //hoja: "",
      hojas: [],
      file: false,
      // Second picker
      data: [] ,
      cols: [],
      alumnsForSend: [],
      dataUploading: false
    };

    this.consoleBulk = this.consoleBulk.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    //Second pickerfhandle
    this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }
  async consoleBulk() {
    let alumnos = [];
    this.setState({  dataUploading: true });
    this.state.data.forEach(al => {
      let alumno = {};
      alumno.Nombre = al["Nombre"];
      alumno.ApellidoP = al["Apellido Paterno"];
      alumno.ApellidoM = al["Apellido Materno"];
      alumno.idEscuela = this.props.idEscuela;
      alumno.CondicionesMedicas = al["Condiciones Medicas"];
      alumno.Balance = al["Balance"];
      alumno.Horario = al["Horario"];
      alumno.FechaNacimiento = al["Fecha de Nacimiento"];
      alumno.FechaInscripcion = al["Fecha de Inscripcion"];
      alumno.StripeCustomerId = null;
      alumno.idFamilia = null;
      let ContactosTpm = [];
      this.addContact(al["Nombre contacto 1"], al["Parentezco Contacto 1"],al["Telefono contacto 1"],ContactosTpm);
      this.addContact(al["Nombre contacto 2"], al["Parentezco Contacto 2"],al["Telefono contacto 2"],ContactosTpm);
      this.addContact(al["Nombre contacto 3"], al["Parentezco Contacto 3"],al["Telefono contacto 3"],ContactosTpm);
      alumno.Contactos = ContactosTpm;
      alumnos.unshift(alumno);
    });
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(alumnos),
    };
    console.log("body", requestOptions.body);
    //await fetch('https://kometaapi.azurewebsites.net/api/alumnosEscuela/'+this.props.idEscuela, requestOptions)
    await fetch('http://localhost:7071/api/ValidacionPreviaCargaMasiva/', requestOptions)
    .then(async (response) =>  {
      if (response.status === 400){
        let conflictingResults = await response.json();
        console.log(conflictingResults);
        let conflictingIds = conflictingResults.map((object) => object.idFamilia +', '+ object.Nombre).join(' - ');
        
        notification.error({
          message: 'Error',
          description: "Conflicto en información de contacto de alumnos nuevos con alumnos ya existentes: " + conflictingResults.length +": =>" + " " + conflictingIds,
          style:{marginTop: 80}
        });
        this.setState({  dataUploading: false });
    }
    else{
       fetch('https://kometaapi.azurewebsites.net/api/alumnosEscuela/'+this.props.idEscuela, requestOptions)
        .then((response) => response.json() )
        .then((data) => {
          //Si existe la propiedad errorDetail es que el API regresó un error con el detalle 
          if(data.errorDetail)
          {
            notification.error({
              message: 'Error',
              description: data.errorDetail,
              style:{marginTop: 80}
            });
          }
          else{
              notification.success({
                message: 'Completado',
                description: 'Alumnos cargados correctamente!',
                style:{marginTop: 80}
              });
          } 
        })
        .catch(error => {
          console.error('Error al guardar!', error);
        });
      this.setState({  dataUploading: false });
      this.props.onHide();
      }
    })
  }

  
  //Validate if imported contacts from the excel sheet actually have info ont he contacts column, if all 3 fields are there contact is valid
  addContact(nombre, parentezco, telefono, Contactos) {
    // Check if any of the fields are empty
    if (!nombre || !parentezco || !telefono) {
      console.log("Error: All fields are required to create a new alumno contact");
      return;
    }
    
    // All fields are non-empty, so create a new Contactos object and add it to the array
    let newContact = { Nombre: nombre, Parentezco: parentezco, Telefono: telefono };
    Contactos.push(newContact);
    //console.log("Contact added successfully!");
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let hojas = []

    //Validate if an actual file was selected
    if (!event.target.files) {
       this2.setState({hojas: []});
      return;
    }

    const this2 = this
    this.setState({
      [name]: value
    })
    
    if (name === 'file') {
      let reader = new FileReader()
      reader.readAsArrayBuffer(target.files[0])
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          hojas.push({
            data: XL_row_object,
            sheetName
          })
        })
        this2.setState({
          selectedFileDocument: target.files[0],
          hojas
        })
      }
    }
  }

  //Second Picker
  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      console.log(rABS, wb);

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 2, raw: false });
      console.log("Alumnos: ", data);
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }
  render() {
    return (
      <Modal title="Carga masiva de alumnos" open={this.props.show} getContainer={false} onCancel={this.props.onHide}
        footer={[<Button variant="secondary" onClick={this.props.onHide} disabled={this.state.dataUploading}>
          Cancelar
        </Button>,
        <Button type="submit"  variant="primary" loading={this.state.dataUploading} onClick={this.consoleBulk}>
          {/* <Button type="submit" variant="primary"> */}
          Guardar
        </Button>]}>

        <h6>Selecciona el archivo de entrada</h6><br/>

        <DragDropFile handleFile={this.handleFile}>
          <div className="row">
            <div className="col-xs-12">
              <DataInput handleFile={this.handleFile} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <OutTable data={this.state.data} cols={this.state.cols} />
            </div>
          </div>
        </DragDropFile>
        <br />
        <a style={{marginLeft:"70%"}} href={AlumnosTemplate} download="TemplateAlumnos" target='_blank' rel="noreferrer" disabled={this.state.dataUploading}> Descargar Template</a>
        <br />
        <br />
      </Modal>
    )
  }
}

export default AlumnosBulk


class DragDropFile extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          {/* <label htmlFor="file">Spreadsheet</label> */}

          {/* <input
            type="file"
            // className="custom-file-input ml-3"
            // className="form-control "
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          /> */}

          <div style={styles}>
            <label className="custom-file-upload ml-3">
              <Input type="file"
                // className="custom-file-input ml-3"
                // className="form-control "
                id="file"
                accept={SheetJSFT}
                onChange={this.handleChange} /> <UploadOutlined className="ml-1 mr-2" /> Subir archivo
            </label>
          </div>

        </div>
      </form>
    );
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        {/* <table className="table table-striped">
          <thead>
            <tr>
              {this.props.cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((r, i) => (
              <tr key={i}>
                {this.props.cols.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table> */}
        <br />
        <p className="ml-3">Se leyeron {this.props.data.length} alumnos del archivo</p>
        
        {/* <p className="ml-3" >{this.props.data.length} alumnos cargados con errores</p> */}

      </div>
      // <div>
      //   <br />
      //   <p>{this.props.data}</p>
      // </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
