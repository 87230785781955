import React, { Component } from "react";
import { Tab, Row, Col, ListGroup } from "react-bootstrap";
import { Spin, Modal, Input,Transfer, message, Button, Select, Divider, Space, Tag, Popconfirm  } from "antd";
import { DeleteOutlined, PlusOutlined, BookOutlined } from "@ant-design/icons";

export class GruposUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GruposData: this.props.gruposData,
      AlumnosData: [],
      MaestrosData: [],
      SchoolPaymentPlans: null,
      SchoolPaymentPlansFormatted: null,
      AlumnosDataIsLoading: true,
      MaestrosDataIsLoading: true,
      showModal: false,
      showAddGroup: false,
      NombreGrupo: null,
      idGroupFilter: "",
      IdForDelete: null,
      groupForDelete: null,
      // Alumno Keys for add group
      SourceAlumnos: [],
      AlumnoTargetKeys: null,
      AlumnoSelectedKeys: [],
      initialAlumnTargets: [],
      // Maestro Keys for add group
      MaestroTargetKeys: null,
      MaestroSelectedKeys: [],
      initialMaestroTargets: [],
      AssignedPaymentPlanIds: [],
      DataLoading: true,
      ShowPaymentPlanModal: false
    };
  }

  //Handler for move items modal
  toggleShowPaymentPlanModal = () => {
    this.setState({ ShowPaymentPlanModal: !this.state.ShowPaymentPlanModal });
  };

  //Handler for move items modal
  handleCloseEdit = () => {
    this.setState({ showModal: false });
  };
  handleShowEdit = () => {
    this.setState({ showModal: true });
  };

   handleRemoveAssignedPlan = (removedTag) => {
    const newTags = this.state.AssignedPaymentPlanIds.filter((tag) => tag !== removedTag);
    console.log(removedTag);
    this.setState({AssignedPaymentPlanIds: newTags});
  };


  //Handler for add Group modal
  handleCloseAddGroup = () => {
    this.setState({ showAddGroup: false });
  };

  handleShowAddGroup = () => {
    this.filterAlumnsTargetForCreate();
    this.setState({ AlumnoTargetKeys: [], MaestroTargetKeys: [], NombreGrupo: "", showAddGroup: true });
  };

  handleSelectPaymentPlan = (value) => {
    console.log(value);
    //Agregar el nuevo plan seleccionado al arreglo
    let tmpArray = [...this.state.AssignedPaymentPlanIds, value];
    console.log(tmpArray);
    let dedupArray = [...new Set(tmpArray)];
    console.log(dedupArray);

    //Agregar al state el arreglo sin duplicaos
    this.setState({ AssignedPaymentPlanIds: dedupArray });
  };

 handleAssignedPlansChange = (e) => {
          this.updateGroup(); 
          this.toggleShowPaymentPlanModal();
  }

  getAlumnosData = async () => {
    await fetch( `https://kometaapi.azurewebsites.net/api/alumnosEscuelaAgrupados/${this.props.idEscuela}`)
    //await fetch(`http://localhost:7071/api/alumnosEscuelaAgrupados/` + this.props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((al) => {
          al.key = al.idAlumno;
          al.title = al.Nombre;
          al.description = al.ApellidoP;

        });

        this.setState({ AlumnosData: data, AlumnosDataIsLoading: false });
      })
      .catch((error) => console.log(error));
  };

  getPaymentPlans = async () => {
    var formattedPaymentPlans = [];
    var value = null;
    var label = null;
    await fetch( `https://kometaapi.azurewebsites.net/api/PlanesPago/` + this.props.idEscuela)
    //await fetch(`http://localhost:7071/api/PlanesPago/` + this.props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        //console.log("payment plans", data)
        //formatear los planes de pago para el dropdown (select)
        data.forEach((_pp) => {
          value=_pp._id;
          label=_pp.PlanName +'  ($ '+_pp.BasePrice+')'
          formattedPaymentPlans.push({"value": value, "label":label})
        });
        
        this.setState({ SchoolPaymentPlansFormatted: formattedPaymentPlans, SchoolPaymentPlans: data });
      })
      .catch((error) => console.log(error));
  };

  getMaestrosData = async () => {
    await fetch(`https://kometaapi.azurewebsites.net/api/maestrosEscuela/${this.props.idEscuela}`)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((mtro) => {
          mtro.key = mtro.IdMaestro;
          mtro.title = mtro.Nombre;
          mtro.description = mtro.Telefono;
        });
        this.setState({ MaestrosData: data, MaestrosDataIsLoading: false });
      })
      .catch((error) => console.log(error));
  };

  findAlumn(alumno) {
    const alm = this.state.AlumnosData.find((a) => a.idAlumno === alumno);
    //console.log(alumno, alm);
    const nombre = alm ? alm.Nombre : "Alumno eliminado";
    return nombre;
  }

  filterTeachers = () => {
    let tchrs;
    tchrs = this.props.gruposData?.filter(m => m.IdGrupo === this.state.idGroupFilter)[0].IdsMaestros;
    this.setState({ MaestroTargetKeys: tchrs });
    //console.log("Teachers: ", this.state.MaestroTargetKeys);
  };

  filterAlumnsTarget = () => {
    let alumns = [];
    this.state.AlumnosData.forEach(a => {
      if (a.IdGrupo === this.state.idGroupFilter || a.IdGrupo === "Sin Grupo") { alumns.push(a) }
    });
    this.setState({ SourceAlumnos: alumns }, () => {
      let rightSide = [];
      this.state.SourceAlumnos.forEach(a => {
        if (a.IdGrupo === this.state.idGroupFilter) { rightSide.push(a.idAlumno) };
      });
      this.setState({ AlumnoTargetKeys: rightSide });
    });
  };

  filterAlumnsTargetForCreate = () => {
    let alumns = [];
    this.state.AlumnosData.forEach(a => {
      if (a.IdGrupo === "Sin Grupo") { alumns.push(a); console.log("Found", a) }
    });
    this.setState({ SourceAlumnos: alumns });
  };

  findMaestro(maestro) {
    const mtro = this.state.MaestrosData.find((a) => a.IdMaestro === maestro);
    return mtro?.Nombre;
  }

  createGroup = async () => {
    if (this.state.NombreGrupo == null || this.state.NombreGrupo.length < 3) {
      window.alert("Ingresa nombre correcto del grupo");
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NombreGrupo: this.state.NombreGrupo,
          idEscuela: this.props.idEscuela,
          IdsAlumnos: this.state.AlumnoTargetKeys,
          IdsMaestros: this.state.MaestroTargetKeys,
          AssignedPaymentPlanIds: this.state.AssignedPaymentPlanIds
        }),
      };
      //console.log(requestOptions.body);
      await fetch( `https://kometaapi.azurewebsites.net/api/gruposEscuela/${this.props.idEscuela}?code=n5unGz6UROVm3nLp_msVp1erbmk4c_UeKSQF4ReEZiNSAzFuxCzDzg==`, requestOptions )
      //await fetch(`http://localhost:7071/api/gruposEscuela/${this.props.idEscuela}`, requestOptions )
        .then((response) => {
          if(response.status !== 200)
         {
            message.error({
              content: 'Error al crear grupo',
              className: 'custom-class',
              style: {
                marginTop: '8vh',
              },
            })
          }
        })
        .catch((error) => {
          console.error("Error al crear grupo!", error);
        })
        .finally(() => {
          this.getMaestrosData();
          //this.getGruposData();
          this.getAlumnosData().then(() =>
            this.filterAlumnsTarget()
          )
          this.handleCloseAddGroup();
        })

    }
  };

  updateGroup = async () => {

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        IdGrupo: this.state.idGroupFilter,
        NombreGrupo: this.state.NombreGrupo,
        IdEscuela: this.props.idEscuela,
        IdsAlumnos: this.state.AlumnoTargetKeys,
        IdsMaestros: this.state.MaestroTargetKeys,
        AssignedPaymentPlanIds: this.state.AssignedPaymentPlanIds
      }),
    };
    //console.log('onUpdate: ', requestOptions.body);
    await fetch(
      `https://kometaapi.azurewebsites.net/api/gruposEscuela/${this.props.idEscuela}?code=n5unGz6UROVm3nLp_msVp1erbmk4c_UeKSQF4ReEZiNSAzFuxCzDzg==`,
      requestOptions
    )
      .then((response) => {
        if(response.status !== 200)
        {
            message.error({
                content: 'Error al grabar los cambios: ' + response.status,
                className: 'custom-class',
                style: {
                  marginTop: '8vh',
                },
              });
        }
        else
            return response.json()
      })
      .then((updatedGroup) => {
       
        //la api de update grupo esta regresando el objecto actualizado en camel Case, a diferencia del get inicial donde si se regresan en pascal case, por lo tanto
        //es necesario crear un objeto temporal para ponerlo en el correcto y poder desplegar la info de los grupos correctamente
        const updatedGroupObject = {
            IdEscuela: updatedGroup.idEscuela,
            IdGrupo: updatedGroup.idGrupo,
            IdsAlumnos: updatedGroup.idsAlumnos,
            IdsMaestros: updatedGroup.idsMaestros,
            NombreGrupo: updatedGroup.nombreGrupo,
            AssignedPaymentPlanIds: updatedGroup.assignedPaymentPlanIds,
            _id: updatedGroup._id,
          };

        //De toda la lista de grupos quitamos el objecto con la info vieja e insertamos el nuevo
        var tmpGroupData = [];
        tmpGroupData = this.state.GruposData.filter(g => g.IdGrupo !== updatedGroupObject.IdGrupo);
        tmpGroupData.push(updatedGroupObject)
        //this.setState({ GruposData: tmpGroupData });
        this.props.updateGrupos(tmpGroupData);
        
      })
      .catch((error) => {
        //console.error("Error al guardar!", error);
        message.error({
            content: 'Error al grabar los cambios: ' + error ,
            className: 'custom-class',
            style: {
              marginTop: '8vh',
            },
          });
      })
      .finally(() => {
        this.getMaestrosData();
        //this.getGruposData();
        this.getAlumnosData().then(() =>
          this.filterAlumnsTarget()
        )
        this.handleCloseEdit();
      })
  };
  
  deleteGroup = async () => {
    //console.log("selected group: ", this.state.IdForDelete);
    if (window.confirm("Estas seguro de borrar el grupo?")) {
      await fetch(
        `https://kometaapi.azurewebsites.net/api/EliminarGrupo/${this.state.IdForDelete}`
      )
        .then((response) => response)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error));
    }
  };

  componentDidMount= async () => {
    //Si no trae la info en props (reload no considerado o error) mandamos al user al home para asegurar que se cargue la info
    //console.log(this.props.gruposData)
    if(this.props.gruposData == null)
        window.location.href = '/';

    this.getMaestrosData();
    await this.getPaymentPlans();
    await this.getAlumnosData().then(() =>
      this.filterAlumnsTarget()
    ).finally(this.setState({DataLoading: false}))
    
  }

  //Transfer for Add / Create group
  // Transfer For Alumnos in add group tralmet tradalmet
  onAlumnosChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ AlumnoTargetKeys: nextTargetKeys });
  };
  onAlumnosSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      AlumnoSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  // Transfer For Maestros in add group trmtrmet
  onMaestrosChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ MaestroTargetKeys: nextTargetKeys });
  };
  onMaestrosSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      MaestroSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  render() {
    return (
      <div className="container">
        <h5>Grupos y maestros</h5>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button type="outline" danger onClick={this.deleteGroup} disabled={this.state.IdForDelete !== null? false: true}> <DeleteOutlined /> Borrar</Button>
                <Button type="outline" onClick={this.handleShowEdit} disabled={this.state.IdForDelete !== null? false: true}> <PlusOutlined /> Editar</Button>
                <Button type="outline" onClick={this.handleShowAddGroup}> <PlusOutlined /> Nuevo</Button>
        </div>

        {this.state.DataLoading ? (
          <div className="spinner"> <Spin /> </div>
          ) : (
          <div style={{backgroundColor: "#E7E7E7", border: 1, paddingTop: 20}}>

                  <Tab.Container id="list-group-tabs-example" defaultActiveKey="default" >
                    <Row>
                      <Col sm={3} style={{backgroundColor: "white", marginLeft: 40, marginRight: 20}}>
                         <h6 style={{marginTop: 10}}>Selecciona un grupo</h6>
                        <ListGroup>

                          {/* Lista de grupos */}
                          {
                            this.state.GruposData?.map((gpo) => {
                              return (
                                <ListGroup.Item
                                  key={gpo.IdGrupo}
                                  action
                                  href={"#" + gpo.IdGrupo}
                                  onClick={() => {
                                    this.setState({ IdForDelete: gpo._id, idGroupFilter: gpo.IdGrupo, NombreGrupo: gpo.NombreGrupo, AssignedPaymentPlanIds: gpo.AssignedPaymentPlanIds }, () => { this.filterAlumnsTarget(); this.filterTeachers() });
                                  }}
                                >
                                  {gpo.NombreGrupo}
                                </ListGroup.Item>
                              );
                            })
                          }
                          <br />

                        </ListGroup>
                      </Col>
                      <Col sm={8} style={{backgroundColor: "white"}}>
                        <Tab.Content>
                          {/* Tarjeta detalles de grupos*/}
                          {
                            
                            this.props.gruposData?.map((gpo) => {

                              //Para cada grupo inicializamos los planes de pago en el state, por si el usuario quiere modificar se muestre a la hora de abrir el modal de payment plans
                              //this.setState({AssignedPaymentPlanIds: gpo.AssignedPaymentPlanIds})

                              return (
                                <Tab.Pane eventKey={"#" + gpo.IdGrupo} key={gpo.IdGrupo}>
                                  <Row style={{justifyContent: "center", marginBottom: 50}}>
                                      <h6 style={{marginTop: 15}}>Nombre del grupo: {gpo.NombreGrupo}</h6>
                                      {/*<Input defaultValue={matchedObject? '$ '+matchedObject.BasePrice: "Sin asignar"} disabled  style={{width: 150, marginLeft:10}}/> */}
                                      <Divider orientation="left">Cobros asignados</Divider>
                                          <Space size={[0, 8]} wrap style={{flex:1 , justifyContent: "space-around"}}>
                                            <div>
                                            {gpo.AssignedPaymentPlanIds?.length > 0?
                                                gpo.AssignedPaymentPlanIds.map((PlanId) => {
                                                    let PlanInfo = this.state.SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId)
                                                    //console.log(PlanInfo.label)
                                                    return(<Tag color="blue">{PlanInfo.label}</Tag>)
                                                }) :
                                                <p>No hay cobros asignados</p>
                                            }
                                           </div>
                                          <Button onClick={this.toggleShowPaymentPlanModal}> Modificar </Button>
                                          </Space>
                                  </Row>
                                  <p>Maestros</p>
                                  {this.state.MaestrosDataIsLoading ? (
                                    <div className="spinner">
                                      <Spin />
                                    </div>
                                  ) : (
                                    <ListGroup>
                                      {gpo.IdsMaestros.map((mtro) => {
                                        return (
                                          <ListGroup.Item key={mtro + gpo.IdGrupo}>
                                            <Row>
                                              {" "}
                                              <BookOutlined />{" "}
                                              <div className="ml-2">
                                                {this.findMaestro(mtro)}
                                              </div>{" "}
                                            </Row>
                                          </ListGroup.Item>
                                        );
                                      })}
                                    </ListGroup>
                                  )}
 
                                  <p>Alumnos</p>
                                  {this.state.AlumnosDataIsLoading ? (
                                    <div className="spinner">
                                      <Spin />
                                    </div>
                                  ) : (
                                    <ListGroup>

                                      {
                                        this.state.AlumnosData.map((a) => {
                                          return a.IdGrupo === this.state.idGroupFilter ?
                                            ( <ListGroup.Item key={a.IdAlumno}>{a.Nombre} {a.ApellidoP} {a.ApellidoM}</ListGroup.Item>) 
                                            : 
                                            ( <></> );

                                        })
                                      }
                                    </ListGroup>
                                  )}
                                  
                                </Tab.Pane>
                              );
                            })
                          }
                          <Tab.Pane eventKey="default">
                            {" "}
                              <p style={{padding: 50}}>Selecciona un grupo del menu de la izquierda</p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <br />
                  <br />
                  {/* End of main component */}

                  <Modal width={620} title="Modificar cobros asignados al grupo" open={this.state.ShowPaymentPlanModal} destroyOnClose={true} onCancel={this.toggleShowPaymentPlanModal}
                    footer={[
                      <Button key="back" onClick={this.toggleShowPaymentPlanModal}>
                        Cancelar
                      </Button>,
                       <Popconfirm
                       title="Deseas cambiar los cobros asignados a este grupo?"
                       onConfirm={this.handleAssignedPlansChange}
                       //onCancel={cancel}
                       okText="Si"
                       cancelText="Cancelar"
                     >
                           <Button> Asignar</Button>,
                     </Popconfirm>
                     
                    ]}>

                        <div>
                            <h6>Cobros asignados</h6>

                             {this.state.AssignedPaymentPlanIds?.length > 0?
                                    this.state.AssignedPaymentPlanIds.map((PlanId) => {
                                    let PlanInfo = this.state.SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId)
                                    //console.log(PlanInfo.label)
                                    return(<Tag color="blue" closable key={PlanInfo.value} onClose={() => this.handleRemoveAssignedPlan(PlanInfo.value)}>{PlanInfo.label}</Tag>)
                                  }) :
                                    <p>No hay cobros asignados</p>
                              }
                        </div>

                        <h6  style={{  marginTop: 25 }}>Agregar nuevo cobro</h6>
                        <Select
                              defaultValue="Selecciona plan de pago"
                              options={this.state.SchoolPaymentPlansFormatted}
                              onChange={this.handleSelectPaymentPlan}
                        />

                    </Modal>
                  <Modal width={620} title="Crear Grupo" open={this.state.showAddGroup} destroyOnClose={true} onCancel={this.handleCloseAddGroup}
                    footer={[
                      <Button key="back" onClick={this.handleCloseAddGroup}>
                        Cancelar
                      </Button>,
                      <Button key="submit" type="primary" htmlType="submit" onClick={this.createGroup}>
                        Guardar
                      </Button>,
                    ]}>

                    <Row style={{justifyContent: "center", marginBottom: 20}}>

                        <Input required placeholder="Ingresa el nombre del grupo" onChange={(e) =>
                          this.setState({ NombreGrupo: e.target.value })
                        } style={{width: 260, marginRight: 30}}/>
                    </Row>
                    Selecciona alumnos para el grupo
                    {/* transfer For Alumnos in add group tradal */}
                    {this.state.AlumnosDataIsLoading && !this.state.AlumnosData ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <Transfer
                        dataSource={this.state.SourceAlumnos}
                        titles={["Alumnos", `Grupo: ${this.state.NombreGrupo}`]}
                        targetKeys={this.state.AlumnoTargetKeys}
                        selectedKeys={this.state.AlumnoSelectedKeys}
                        onChange={this.onAlumnosChange}
                        onSelectChange={this.onAlumnosSelectChange}
                        onScroll={this.onScroll}
                        render={(item) =>
                          `${item.title}    ${item.ApellidoP}       (${item.idAlumno})`
                        }
                        listStyle={{
                          width: 350,
                          height: 300,
                        }}
                      />
                    )}
                    Selecciona maestros para el grupo
                    {/* transfer For Maestros in add group tradmtro */}
                    {this.state.MaestrosDataIsLoading &&
                      !this.state.MaestrosData ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <Transfer
                        dataSource={this.state.MaestrosData}
                        titles={["Maestros", `Grupo: ${this.state.NombreGrupo}`]}
                        targetKeys={this.state.MaestroTargetKeys}
                        selectedKeys={this.state.MaestroSelectedKeys}
                        onChange={this.onMaestrosChange}
                        onSelectChange={this.onMaestrosSelectChange}
                        onScroll={this.onScroll}
                        render={(item) => `${item.title}`}
                        listStyle={{
                          width: 350,
                          height: 300,
                        }}
                      />
                    )}
                  </Modal>

                  {/* Modal for editing Group moded modaled modedgr modaledgr*/}
                  <Modal width={620} title="Editar Grupo" open={this.state.showModal} destroyOnClose={true} onCancel={this.handleCloseEdit}
                    footer={[
                      <Button key="back" onClick={this.handleCloseEdit}>
                        Cancelar
                      </Button>,
                      <Button key="submit" type="primary" htmlType="submit" onClick={this.updateGroup}>
                        Guardar
                      </Button>,
                    ]}>

                      <Row style={{justifyContent: "center", marginBottom: 20}}>
                          <Input required defaultValue={this.state.NombreGrupo} onChange={(e) =>
                            this.setState({ NombreGrupo: e.target.value })
                          } style={{width: 260, marginRight: 30}}/>
                      </Row>

                    Alumnos por asignar
                    {/* Select for alumnos alselect */}
                    {this.state.AlumnosDataIsLoading && !this.state.AlumnosData ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      // Transfer in move alumn trmval from edit group tredgr
                      <Transfer
                        dataSource={this.state.SourceAlumnos}
                        titles={["Alumnos", `Grupo: ${this.state.NombreGrupo}`]}
                        targetKeys={this.state.AlumnoTargetKeys}
                        selectedKeys={this.state.AlumnoSelectedKeys}
                        onChange={this.onAlumnosChange}
                        onSelectChange={this.onAlumnosSelectChange}
                        onScroll={this.onScroll}
                        render={(item) =>
                          `${item.title}    ${item.ApellidoP}       (${item.idAlumno})`
                        }
                        listStyle={{
                          width: 380,
                          height: 300,
                        }}
                      />
                    )}
                    
                    Maestros por asignar
                    {/* transfer For Maestros in add group tradmtro */}
                    {this.state.MaestrosDataIsLoading && !this.state.MaestrosData ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <Transfer
                        dataSource={this.state.MaestrosData}
                        titles={["Maestros", `Grupo: ${this.state.NombreGrupo}`]}
                        targetKeys={this.state.MaestroTargetKeys}
                        selectedKeys={this.state.MaestroSelectedKeys}
                        onChange={this.onMaestrosChange}
                        onSelectChange={this.onMaestrosSelectChange}
                        onScroll={this.onScroll}
                        render={(item) => item.title}
                        listStyle={{
                          width: 380,
                          height: 300,
                        }}
                      />
                    )}
                  </Modal>
          </div>
          )}
      </div>
    );
  }
}

export default GruposUI;
