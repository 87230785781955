import React, { useState, useEffect } from 'react';
import { Spin,  Button, Select } from "antd";

import { CloudUploadOutlined  } from "@ant-design/icons";
import { UserDeleteOutlined  } from "@ant-design/icons";
import { UserAddOutlined } from "@ant-design/icons";
import {EditOutlined} from "@ant-design/icons";
import {  message } from "antd";
import AlumnosBulk from "./AlumnosBulk";
import NuevoAlumno from "./NuevoAlumno";
import EditarAlumno from "./EditarAlumno";
import AlumnoDetailCard from "./AlumnoDetailCard"
//import moment from 'moment';
//const { Panel } = Collapse;

const DetalleAlumno = ({ idEscuela, alumnoForEdit, gruposData }) => {
  const [alumnosData, setAlumnosData] = useState(null);
  const [alumnosSearchData, setAlumnosSearchData] = useState(null);
  //const [alumnosDataIsLoading, setAlumnosDataIsLoading] = useState(true);
  //const [incidentes, setIncidentes] = useState(true);
  //const [incidentesIsLoading, setIncidentesIsLoading] = useState(true);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalBulk, setShowModalBulk] = useState(false);
  const [selectedStudentID, setSelectedStudentID] = useState(null);
  const [selectedAlumno, setSelectedAlumno] = useState(alumnoForEdit)
  const [SchoolPaymentPlans, setSchoolPaymentPlans] = useState(null);
  const [SchoolPaymentPlansFormatted, setSchoolPaymentPlansFormatted] = useState(null);
  const [phone, setPhone] = useState(null);
  //const [prefix, setPrefix] = useState(null);

  useEffect(() => {
    getAlumnosData();
    getPaymentPlans();
  }, []);

  const getAlumnosData = () => {
    fetch(`https://kometaapi.azurewebsites.net/api/alumnosEscuela/${idEscuela}`)
    //fetch(`http://localhost:7071/api/alumnosEscuelaAgrupados/${this.props.idEscuela}`)
      .then((response) => response.json())
      .then((data) => {        
        setAlumnosData(data);
        //Crear un array secundario en formato valido para el search box
        var TmpArray = [];
        data.forEach(function (AlumnoItem) {
          TmpArray.push({
            value: AlumnoItem.idAlumno,
            label: AlumnoItem.Nombre + " " + AlumnoItem.ApellidoP + " " + AlumnoItem.ApellidoM,
          });
        });

          //setAlumnosData (data);
          setAlumnosSearchData (TmpArray);
        //setAlumnosDataIsLoading(false);
        //console.log("AlumnosData: ", AlumnosData);
      })
      .catch((error) => console.log(error));
  };

  /*const getIncidentes = async () => {
    if (phone) {
      try {
        const response = await fetch(
          `https://kometaapi.azurewebsites.net/api/BitacoraFamilia/` + phone
        );
        const data = await response.json();
        //setIncidentes(data);
      } catch (error) {
        console.log(error);
      } finally {
        //setIncidentesIsLoading(false);
      }
    }
  };*/

  const crearAlumno = async (values) => {
    values.idEscuela = idEscuela;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify([values]),
    };

    try {
      const response = await fetch(`https://kometaapi.azurewebsites.net/api/alumnosEscuela/${idEscuela}`, requestOptions);
      if (!response.ok) {
        message.error({
          content: 'Error al guardar alumno',
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          }
        });
      }
    } catch (error) {
      console.error('Error al guardar!', error);
    } finally {
      getAlumnosData();
      setShowModalAdd(false);
    }
  };

  const getPaymentPlans = async () => {
    var formattedPaymentPlans = [];
    var value = null;
    var label = null;
    await fetch( `https://kometaapi.azurewebsites.net/api/PlanesPago/` + idEscuela)
    //await fetch(`http://localhost:7071/api/PlanesPago/` + this.props.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        setSchoolPaymentPlans(data);
        //formatear los planes de pago para el dropdown (select)
        data.forEach((_pp) => {
          value=_pp._id;
          label=_pp.PlanName +'  ($ '+_pp.BasePrice+')'
          formattedPaymentPlans.push({"value": value, "label":label})
        });
         setSchoolPaymentPlansFormatted(formattedPaymentPlans);
      })
      .catch((error) => console.log(error));
  };

  /*const updateAlumno = () => {
    setShowModalAdd(true);
  };*/

  const deleteAlumno = () => {
    if (window.confirm("Estas seguro de borrar el alumno?")) {
      fetch(`https://kometaapi.azurewebsites.net/api/EliminarAlumno/` + selectedStudentID)
        .then(response => response.json())
        .then(data => { console.log(data); })
        .catch(error => console.log(error));
      getAlumnosData();
    }
  };

  const setSelectedStudent = (alumno) => {
    
    setSelectedStudentID(alumno.idAlumno);
    setPhone(alumno.Contactos[0].Telefono);
    //setIncidentesIsLoading(true);
    setSelectedAlumno(alumno);
    //getIncidentes();
  };

  const handleShowEdit = () => {
    //console.log("Editing:", selectedAlumno);
    setShowModalEdit(true);
  };

  const handleShowNewStudent = () => {
    setShowModalAdd(true);
  };

  const handleCloseAdd = () => {
    setShowModalAdd(false);
  };

  const handleCloseEdit = () => {
    setShowModalEdit(false);
  };

  const handleShowBulk = () => setShowModalBulk(true);
  const handleCloseBulk = () => setShowModalBulk(false);

  const onChangeInput = (value) => {
    //console.log(`selected ${value}`);
    //console.log(alumnosData.find(al => al.idAlumno === value));
    setSelectedStudent(alumnosData.find(al => al.idAlumno === value));
  };
  const onSearchAlumno = (value) => {
    console.log('search:', value);
    //setSelectedStudent(alumnosData.filter(al => al.idAlumno === value));
  };
  
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className="container">

    <div style={{display: 'flex', flexDirection: "row", marginBottom:40, marginTop: 55, backgroundColor: "white", padding: 25}}>
      <p style={{marginRight: 20}}>¿Qué alumno quieres revisar?</p>
      <Select
                    showSearch
                    placeholder="Buscar Alumno"
                    optionFilterProp="children"
                    onChange={onChangeInput}
                    onSearch={onSearchAlumno}
                    filterOption={filterOption}
                    options={alumnosSearchData}
                    style={{ width: 300}}
                />
    </div>

    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
            
            <Button danger onClick={deleteAlumno}>
                <UserDeleteOutlined /> Borrar 
            </Button>
            <div style={{ marginRight: 15 }} /> {/* Spacer */}
            <Button type="outline" onClick={handleShowBulk}>
                <CloudUploadOutlined /> Carga Masiva
            </Button>
            <div style={{ marginRight: 15 }} /> {/* Spacer */}
            <Button
                type="outline"
                disabled={selectedStudentID === null}
                onClick={handleShowEdit}
            >  
                <EditOutlined /> Editar
            </Button>
            <div style={{ marginRight: 15 }} /> {/* Spacer */}
            <Button type="outline" onClick={handleShowNewStudent}>
                <UserAddOutlined /> Nuevo
            </Button>
        </div>

      <AlumnoDetailCard 
        alumno={selectedAlumno} 
        phone={phone} 
        alumnoForEdit={alumnoForEdit} 
        SchoolPaymentPlansFormatted = {SchoolPaymentPlansFormatted}
        SchoolPaymentPlans = {SchoolPaymentPlans}
        gruposData={gruposData}
      />

      <NuevoAlumno
        Visible={showModalAdd}
        idEscuela={idEscuela}
        handleCloseAdd={handleCloseAdd}
        sendAlumno={crearAlumno}
      />
      <EditarAlumno
        Visible={showModalEdit}
        idEscuela={idEscuela}
        handleCloseEdit={handleCloseEdit}
        //sendAlumno={actualizarAlumno}
        alumnoForEdit={selectedAlumno}
        SchoolPaymentPlansFormatted = {SchoolPaymentPlansFormatted}
        SchoolPaymentPlans = {SchoolPaymentPlans}
        gruposData={gruposData}
      />

      <AlumnosBulk show={showModalBulk} onHide={handleCloseBulk} idEscuela={idEscuela} />
    </div>
  );
};

export default DetalleAlumno;
