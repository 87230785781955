import React, { useState } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { Input, Button, message, Select, Image } from 'antd'
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
const { Option } = Select;


const Login = () => {
    //PoneAuth
    const countryCode = "+52";
    const [phoneNumber, setPhoneNumber] = useState(countryCode);
    const [codeRequested, setCodeRequested] = useState(false);
    const [OTP, setOTP] = useState('');
    const [lada, setLada] = useState('+52')

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, auth);
    }

    const requestOTP = (e) => {
        console.log("Telefono: ", phoneNumber)
        e.preventDefault();
        if (phoneNumber.length >= 10) {
            console.log("Requesting for ", phoneNumber)
            setCodeRequested(true);
            generateRecaptcha();
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, lada + phoneNumber, appVerifier)
                .then(confirmationResult => {
                    console.log(confirmationResult);
                    window.confirmationResult = confirmationResult;
                }).catch((error) => {
                    console.log(error)
                })
        } else message.info('Ingresa 10 digitos')
    }

    const verifyOTP = (e) => {
        if (OTP.length === 6) {
            //Verificar codigo de confirmacion
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(OTP).then((result) => {
                // User signed in successfully.
                const user = result.user;
                message.success('Sesion iniciada correctamente');
                console.log("new token: ", user.accessToken)
                localStorage.setItem('userPhone', user.phoneNumber);
                window.location.href = '/';
                console.log("user auth: ", user);
                message.info('Inicio de Sesion Correcto');
            }).catch((error) => {
                message.error('Login fallido: '+ error);
            });
        }
    }

    const handleChange = (value) => {
        setLada(value)
        console.log(`selected ${value}`);
    };

    const selectBefore = (
        // <Form.Item name="prefix" noStyle>
        <Select
            onChange={handleChange}
            defaultValue='+52'
            style={{
                width: 70,
            }}
            disabled={true}
        >
            <Option value="+52">+52</Option>
            <Option value="+1">+1</Option>
        </Select>
        //  </Form.Item> 
    );

    return (
        <div style={{ "marginTop": "60px" }}>
            <center>
                <Image src={require('../assets/bp_small.png')} style={{marginBottom: 90}} preview={false} />
                <div style={{ display: !codeRequested ? "block" : "none"}}>
                        
                        <h5>Ingresa numero de telefono</h5>
                        <br />
                        <Input
                            addonBefore={selectBefore}
                            style={{ width: 250 }}
                            maxLength={10}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                            }}
                            placeholder="XXXXXXXXXX" />
                        <br /><br />
                        <div id="recaptcha-container"></div>
                        <Button
                            onClick={requestOTP}
                            // onClick={() => console.log(phoneNumber)}
                            type='primary'>Solicitar codigo</Button>

                </div>
                <div style={{ display: codeRequested ? "block" : "none"}}>
                        <h5>Ingresa Código de Verificacion</h5>
                        <br />
                        <Input
                            style={{ width: 150 }}
                            type="text" placeholder={"XXXXXX"}
                            onChange={(e) => { setOTP(e.target.value) }}
                            maxLength={6} />
                        <br /><br />
                        <Button onClick={verifyOTP} type='primary'>Verificar</Button>

                </div>
                <p style={{marginTop: 60}}> 3kites v 1.0 beta</p>
            </center>
        </div>
    );
}

export default Login;