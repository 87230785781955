import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Space, Modal, Avatar, Select, Collapse, Tag, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import moment from 'moment';

const { Panel } = Collapse;

function EditarAlumno(props) {
  const formRef = React.createRef();
  const [form] = Form.useForm();
  const [ShowPaymentPlanModal, setShowPaymentPlanModal] = useState(false);
  const [ShowDiscountModal, setShowDiscountModal] = useState(false);
  const [Discounts, setDiscounts] = useState(props.alumnoForEdit?.AppliedDiscounts || []);
  const [AssignedPaymentPlanIds, setAssignedPaymentPlanIds] = useState(props.alumnoForEdit?.AdditionalPaymentPlans || []);


  const grupoAlumno = props.gruposData?.find(grupo => grupo.IdsAlumnos.includes(props.alumnoForEdit?.idAlumno));
  const GroupPaymentPlans = grupoAlumno?.AssignedPaymentPlanIds || [];

  useEffect(() => {
    if (props.alumnoForEdit) {
      setAssignedPaymentPlanIds(props.alumnoForEdit.AdditionalPaymentPlans || []);
      setDiscounts(props.alumnoForEdit?.AppliedDiscounts || [])
      let copyAlumno = { ...props.alumnoForEdit };
      copyAlumno.FechaInscripcion = moment(copyAlumno.FechaInscripcion);
      copyAlumno.FechaNacimiento = moment(copyAlumno.FechaNacimiento);
      form.setFieldsValue(copyAlumno);
    }
  }, [props.alumnoForEdit, form]);

  /*useEffect(() => {
    console.log('Discounts state updated:', Discounts);
  }, [Discounts]);*/

  const onClose = () => {
    //setAssignedPaymentPlanIds([]);
    //setDiscounts([]);
    props.handleCloseEdit();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
    form.resetFields();
  };

  const toggleShowPaymentPlanModal = () => {
    setShowPaymentPlanModal(!ShowPaymentPlanModal);
  };

  const toggleShowDiscountModal = () => {
    setShowDiscountModal(!ShowDiscountModal);
  };

  const handleRemoveAssignedPlan = (removedTag) => {
    const newTags = AssignedPaymentPlanIds.filter((tag) => tag !== removedTag);
    setAssignedPaymentPlanIds(newTags);
  };

  const handleSelectPaymentPlan = (value) => {
    let tmpArray = [...(AssignedPaymentPlanIds || []), value];
    let dedupArray = [...new Set(tmpArray)];
    setAssignedPaymentPlanIds(dedupArray);
  };

  const handleSelectDiscount = (planId, value) => {
    setDiscounts((prevDiscounts) => {
      const existingIndex = prevDiscounts.findIndex((discount) => discount.RelatedPaymentPlan === planId);
      if (existingIndex >= 0) {
        const updatedDiscounts = [...prevDiscounts];
        updatedDiscounts[existingIndex].Amount = value;
        console.log(updatedDiscounts)
        return updatedDiscounts;
      } else {
        return [...prevDiscounts, { RelatedPaymentPlan: planId, Amount: value }];
      }
    });
  };

  const updateAlumno = async () => {
    let AlumnoActualizado = { ...props.alumnoForEdit, AdditionalPaymentPlans: AssignedPaymentPlanIds, AppliedDiscounts: Discounts };
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AlumnoActualizado),
    };
    //console.log("Updated", AlumnoActualizado);
    await fetch(
      `https://kometaapi.azurewebsites.net/api/actualizarAlumno/?code=PiDtuoTohMScbObEDXds0xne3hE6Hnph-WGq-sDWIsCVAzFuenBy6g%3D%3D`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 200) {
          message.error({
            content: 'Error al grabar los cambios: ' + response.status,
            className: 'custom-class',
            style: {
              marginTop: '8vh',
            },
          });
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        message.error({
          content: 'Error al grabar los cambios: ' + error,
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const combinedPaymentPlans = [...new Set([...AssignedPaymentPlanIds, ...GroupPaymentPlans])];

  return (
    <Modal
      title="Editar Alumno"
      open={props.Visible}
      onCancel={onClose}
      destroyOnClose={true}
      width={1100}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" form="editar_alumno">
          Guardar
        </Button>,
      ]}
    >
      <Form
        ref={formRef}
        form={form}
        name="editar_alumno"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={props.alumnoForEdit}
        onFinish={updateAlumno}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          <Form.Item
            label="Avatar"
            name="Avatar"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 15 }}
            style={{ flex: '0 0 10%', marginTop: 20 }}
          >
            <Avatar icon={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            label="ID Alumno"
            name="idAlumno"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ flex: '0 0 20%' }}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="Id Familia"
            name="idFamilia"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ flex: '0 0 20%' }}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="Fecha Nacimiento"
            name="FechaNacimiento"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ flex: '0 0 15%' }}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item
            label="Fecha Inscripcion"
            name="FechaInscripcion"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ flex: '0 0 15%' }}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
          <Form.Item
            label="Nombre"
            name="Nombre"
            rules={[{ required: true, message: 'Ingresa nombre del alumno' }]}
            style={{ flex: '0 0 30%' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="A. Paterno"
            name="ApellidoP"
            rules={[{ required: true, message: 'Ingresa apellido paterno del alumno' }]}
            style={{ flex: '0 0 30%' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="A. Materno"
            name="ApellidoM"
            rules={[{ required: true, message: 'Ingresa apellido materno del alumno' }]}
            style={{ flex: '0 0 30%' }}
          >
            <Input />
          </Form.Item>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
          <Form.Item
            label="Condiciones Medicas"
            name="CondicionesMedicas"
            rules={[{ required: false, message: 'Ingresa condiciones medicas del alumno' }]}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ flex: '0 0 60%' }}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            label="Horario"
            name="Horario"
            rules={[{ required: true, message: 'Ingresa horario' }]}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ flex: '0 0 30%' }}
          >
            <Input />
          </Form.Item>
        </div>

        <Collapse>
          <Panel header="Contactos" key="1">
            <Form.Item >
              <Form.List
                name="Contactos"
                rules={[
                  {
                    validator: async (_, contactos) => {
                      if (!contactos || contactos.length < 1) {
                        return Promise.reject(new Error('Ingresa al menos un contacto'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 4, flex: '0 0 70%' }} align="start">
                        <Form.Item
                          {...restField}
                          name={[name, 'Parentezco']}
                          rules={[{ required: true, message: 'Ingresa Parentezco' }]}
                        >
                          <Input placeholder="Parentezco" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'Nombre']}
                          rules={[{ required: true, message: 'Ingresa el nombre del familiar' }]}
                        >
                          <Input placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'Telefono']}
                          rules={[{ required: true, message: 'Ingresa telefono' }]}
                        >
                          <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'Carro']}
                        >
                          <Input placeholder="Carro" />
                        </Form.Item>
                        {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                      </Space>
                    ))}
                    <Form.Item style={{ marginLeft: 10 }}>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={fields.length > 3}>
                        Nuevo Contacto
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </Panel>

          <Panel header="Actividades Extracurriculares" key="2">
            <div style={{ display: "flex" }}>
              <Form.Item
                label="Planes asignados"
                style={{ flex: '0 0 50%' }}
              >
                <Space size={[0, 8]} wrap style={{ flex: 1, marginLeft: 20 }}>
                  <div>
                    {AssignedPaymentPlanIds.length > 0 ? (
                      AssignedPaymentPlanIds.map((PlanId) => {
                        let PlanInfo = props.SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId);
                        return <Tag color="blue" key={PlanId}>{PlanInfo?.label}</Tag>;
                      })
                    ) : (
                      <p>No actividades extracurriculares</p>
                    )}
                  </div>
                </Space>
                <Button onClick={toggleShowPaymentPlanModal} type='outline'>Editar</Button>
              </Form.Item>
            </div>
          </Panel>

          <Panel header="Descuentos asignados" key="4">
            <div style={{ display: "flex" }}>
              <Form.Item
                label="Descuentos"
                style={{ flex: '0 0 50%' }}
              >
                <Space size={[0, 8]} wrap style={{ flex: 1, marginLeft: 20 }}>
                  <div>
                    {Discounts.length > 0 ? (
                        Discounts.map((discount, index) => {
                          let PlanInfo = props.SchoolPaymentPlans?.find(_p => _p._id === discount.RelatedPaymentPlan)
                          //console.log(SchoolPaymentPlans)
                          return (<Tag color="orange" key={index}>{PlanInfo?.PlanName} -${discount.Amount} </Tag>)
                      })
                      ) : (
                        <p>No hay descuentos asignados</p>
                      )}
                  </div>
                </Space>
                <Button onClick={toggleShowDiscountModal} type='outline'>Editar</Button>
              </Form.Item>
            </div>
          </Panel>

          <Panel header="Expediente" key="3">
            <p>Placeholder</p>
          </Panel>
        </Collapse>
      </Form>

      <Modal width={620} title="Modificar cobros extracurriculares asignados al alumno" open={ShowPaymentPlanModal} destroyOnClose={true} onCancel={toggleShowPaymentPlanModal}
        footer={[
          <Button key="back" onClick={() => { setAssignedPaymentPlanIds(props.alumnoForEdit.AdditionalPaymentPlans); toggleShowPaymentPlanModal() }}>
            Cancelar
          </Button>,
          <Button onClick={toggleShowPaymentPlanModal}>Asignar</Button>
        ]}>
        <div>
          <h6>Cobros asignados</h6>
          <div>
            {AssignedPaymentPlanIds && AssignedPaymentPlanIds.length > 0 ? (
              AssignedPaymentPlanIds.map((PlanId) => {
                let PlanInfo = props.SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId);
                return <Tag color="blue" closable key={PlanInfo?.value} onClose={() => handleRemoveAssignedPlan(PlanInfo?.value)}>{PlanInfo?.label}</Tag>;
              })
            ) : (
              <p>No actividades extracurriculares</p>
            )}
          </div>
        </div>
        <h6 style={{ marginTop: 25 }}>Agregar nuevo cobro</h6>
        <Select
          defaultValue="Selecciona plan de pago"
          options={props.SchoolPaymentPlansFormatted}
          onChange={handleSelectPaymentPlan}
        />
      </Modal>

      <Modal width={620} title="Asignar Descuentos a los Planes de Pago" open={ShowDiscountModal} destroyOnClose={true} onCancel={toggleShowDiscountModal}
        footer={[
          <Button key="back" onClick={toggleShowDiscountModal}>
            Cancelar
          </Button>,
          <Button onClick={toggleShowDiscountModal}>Ok</Button>
        ]}>
        <div>
          <h6>Descuentos</h6>
          {combinedPaymentPlans.length > 0 ? (
            combinedPaymentPlans.map((PlanId) => {
              //Get formatted plan detail
              let PlanInfo = props.SchoolPaymentPlansFormatted?.find(_p => _p.value === PlanId);

              //Validate if selected student already as discount assigned
              const existingDiscount = Discounts.find(discount => discount.RelatedPaymentPlan === PlanId);

              console.log("planinfo: ", PlanInfo)
              return (
                <div key={PlanId} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Tag color="blue">{PlanInfo?.label}</Tag>
                  <Input 
                    placeholder="Descuento" 
                    type="number" 
                    defaultValue={existingDiscount ? existingDiscount.Amount : 0}
                    min={0} 
                    onChange={(e) => handleSelectDiscount(PlanId, e.target.value)} 
                    style={{ width: '30%', marginLeft: '10px' }} 
                  />
                </div>
              );
            })
          ) : (
            <p>No hay planes descuentos asignados</p>
          )}
        </div>
      </Modal>
    </Modal>
  );
}

export default EditarAlumno;
