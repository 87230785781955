import React from 'react'
import { Result, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

export default function ExceptionView(props) {

    //const loginCardStyile = { height: 400, width: 400, justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column' }
    const { Paragraph, Text } = Typography;

    return (
        <div style={{ "marginTop": "120px" }}>
            <center>
                <Result
                    //status="error"
                    title="El inicio de sesión no se puede completar"
                    subTitle= {props.errorMessage !== null? props.errorMessage : "Tu usuario no está registrado con ninguna escuela."}
                >
                    <div className="desc">
                    <Paragraph>
                        <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                        >
                        Para revisar el problema puedes:
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> Contactar al director del colegio para validar que tu número de teléfono se registro correctamente.
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> Enviar un correo a soporte técnico para reivsar a detalle. 
                    </Paragraph>
                    </div>
                </Result>
                {/*<div style={{ display: 'block', width: '38%' }}>
                    <Card hoverable bodyStyle={loginCardStyile}>
                        <h5>No estas registrado en ninguna escuela</h5>
                        <br />
                        <WarningOutlined style={{ fontSize: 64, color: 'yellow' }} />
                        <br />
                        <Button type="primary" onClick={props.logout}>Cerrar sesion</Button>
                    </Card>
                    </div>*/}
            </center>
        </div>
    )
}
