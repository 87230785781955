import React from 'react'
import { Spin } from "antd";

export default function LoadingView() {
  return (
    <div style={{
      textAlign: 'center',
      padding: '30px 50px',
      margin: '200px 0',
    }}>
      <h5 style={{ color: '#1890ff' }}>Cargando</h5>
      <br />
      <Spin />
    </div >
  )
}
