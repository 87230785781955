import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
/*const firebaseConfig = {
  apiKey: "AIzaSyCO3exd0l_uOWKt4Zo_aBQ4NSZl49VCRHY",
  authDomain: "kometa-f0568.firebaseapp.com",
  projectId: "kometa-f0568",
  storageBucket: "kometa-f0568.appspot.com",
  messagingSenderId: "123428133219",
  appId: "1:123428133219:web:ec78261d5d567a998edb56"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyC5K9q3qvw6EFTH3ezqxAcQr3FABs55ZdQ",
  authDomain: "kometa-web-socket.firebaseapp.com",
  databaseURL: "https://kometa-web-socket-default-rtdb.firebaseio.com",
  projectId: "kometa-web-socket",
  storageBucket: "kometa-web-socket.appspot.com",
  messagingSenderId: "3674394795",
  appId: "1:3674394795:web:465a0d7e03176be382e7ee",
  measurementId: "G-9L5KS655KM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);