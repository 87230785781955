import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Spin, Tag, Form, Image, Button, Modal,Table, Input, Space, DatePicker, Select  } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
//import moment from "moment";
const { Option } = Select;


const Bitacora = (props) => {
  const [showModal, setShowModal] = useState(false);
  //const [desc, setDesc] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  //const [descripcion, setDescripcion] = useState(null);
  //const [idAlumno, setIdAlumno] = useState(null);
  //const [clasificacion, setClasificacion] = useState(null);
  const [alumnosData, setAlumnosData] = useState([]);
  const [alumnosDataIsLoading, setAlumnosDataIsLoading] = useState(true);
  const [bitacoraData, setBitacoraData] = useState(null);
  const [bitacoraDataIsLoading, setBitacoraDataIsLoading] = useState(true);
  const formRef = React.createRef();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {setShowModal(false); form.resetFields(); setStartDate(new Date())};

  // Format data to display in Bitacora's table
  const getBitacoraData = async () => {
    try {
      const response = await fetch(
        `https://kometaapi.azurewebsites.net/api/BitacoraEscuela/${props.idEscuela}`
      );
      const data = await response.json();
      const formattedData = data.map((evt) => {
        evt.key = evt._id;
        evt.Alumno = `${evt.Nombre} ${evt.ApellidoP} ${evt.ApellidoM}`;
        evt.value = `${evt.Nombre} ${evt.ApellidoP} ${evt.ApellidoM}`;
        evt.Fecha = evt.Fecha.substring(0, 10);
        return evt;
      });
      setBitacoraData(formattedData);
      setBitacoraDataIsLoading(false);
      console.log("BitDataOnGet", formattedData);
      console.log("DataOnGetBit", data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAlumnosData = () => {
    fetch(
      `https://kometaapi.azurewebsites.net/api/alumnosEscuelaAgrupados/${props.idEscuela}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("AlumnosDataBitacora: ", data);

        // Filter out alumnos without a group assigned
        const filteredAlumnos = data.filter((m) => m.IdGrupo !== "Sin Grupo");

        setAlumnosData(filteredAlumnos);
        setAlumnosDataIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const sendBitacora = (values) => {
    values.idEscuela = props.idEscuela;

    // The form for adding a new bitacora event from the admin GUI doesn't allow adding photos, so a null value is manually inserted
    values.urlImagen = null;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    console.log(requestOptions.body);
    fetch(
      `https://kometaapi.azurewebsites.net/api/BitacoraEscuela/${props.idEscuela}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error al guardar!", error);
      })
      .finally(() => {
        getAlumnosData();
        getBitacoraData();
        handleClose();
      });
  };

  useEffect(() => {
    getBitacoraData();
    getAlumnosData();
  }, []);

  const handleSelect = (e) => {
    console.log(e);
    //setClasificacion(e);
  };

  // Table functions
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // End of table functions

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "Clasificacion",
      dataIndex: "Clasificacion",
      key: "Clasificacion",
      ...getColumnSearchProps("Clasificacion"),
      sorter: (a, b) => a.Clasificacion.length - b.Clasificacion.length,
      sortDirections: ["descend", "ascend"],
      render: (Clasificacion) => (
        <>
          {" "}
          <Tag
            color={Clasificacion === "Informativo" ? "#52c41a" : "#eb2f96"}
            key={Clasificacion}
          >
            {" "}
            {Clasificacion}{" "}
          </Tag>{" "}
        </>
      ),
    },
    {
      title: "Imagen",
      dataIndex: "urlImagen",
      key: "urlImagen",
      render: (Imagen) => (
        <>
            {Imagen? 
                <Image
                    width={48}
                    height={48}
                    src={Imagen}
                    onError={(e) => (e.target.style.display = "none")}
                /> :
                <></>
            }
        </>
      ),
    },
    {
      title: "Alumno",
      dataIndex: "Alumno",
      key: "Alumno",
      width: "20%",
      ...getColumnSearchProps("Alumno"),
      sorter: (a, b) => a.Alumno.length - b.Alumno.length,
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "30%",
      ...getColumnSearchProps("Descripcion"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      //...getColumnSearchProps("Fecha"),
      //sorter: (a, b) => a.Fecha.length - b.Fecha.length,
      //sortDirections: ["descend", "ascend"],
    },
    {
      title: "Grupo",
      dataIndex: "NombreGrupo",
      key: "NombreGrupo",
      ...getColumnSearchProps("NombreGrupo"),
      sorter: (a, b) => a.NombreGrupo.length - b.NombreGrupo.length,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <>
    <div className="container">
          <Row className="p-2">
            <h5>Bitacora</h5>
            <hr />
            <Button type="outline" onClick={handleShow}>
                <PlusOutlined /> Agregar evento
            </Button>
          </Row>

          {bitacoraDataIsLoading ? (
            <div className="spinner">
              <Spin />
            </div>
          ) : (
            <Table columns={columns} dataSource={bitacoraData} id="" />
          )}
      </div>

      <Modal
        title="Nuevo evento"
        open={showModal}
        getContainer={false}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="agregar-registro"
          >
            Guardar
          </Button>,
        ]}
      >
        <Form
          ref={formRef}
          form={form}
          name="agregar-registro"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={sendBitacora}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Descripcion"
            name="Descripcion"
            rules={[{ required: true, message: "Ingresa descripcion" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Alumno"
            name="idAlumno"
            rules={[{ required: true, message: "Selecciona el alumno" }]}
          >
            <Select defaultValue={alumnosData[0]?._id}>
              {alumnosDataIsLoading ? (
                <Option value="">Ninguno</Option>
              ) : (
                alumnosData.map((a) => (
                  <Option value={a.idAlumno}>
                    {a.Nombre + " " + a.ApellidoP + " " + a.ApellidoM}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Clasificacion"
            name="Clasificacion"
            rules={[
              { required: true, message: "Selecciona la clasificacion" },
            ]}
          >
            <Select onChange={handleSelect}>
              <Option value="Pañal">Pañal</Option>
              <Option value="Alimento">Alimento</Option>
              <Option value="Siesta">Siesta</Option>
              <Option value="Actividad">Actividad</Option>
              <Option value="Incidente">Incidente</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Fecha"
            name="Fecha"
            rules={[{ required: true, message: "Selecciona la fecha" }]}
          >
            <DatePicker
              //value={moment(startDate, "YYYY-MM-DD")}
              format="YYYY-MM-DD"
              on={(date, dateString) => setStartDate(dateString)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Bitacora;
