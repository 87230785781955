import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, message, Table, Input, Space, Card, Spin, Modal, Radio } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

function PagosCash(props) {
  const [PagosPendientes, setPagosPendientes] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [MetodoPago, setMetodoPago] = useState("Tarjeta");
  const [ReferenciaPago, setReferenciaPago] = useState(null);
  const [SelectedPayment, setSelectedPayment] = useState(null);
  const searchInput = useRef(null);
  const [open, setOpen] = useState(false);

  const showModal = (paymentId) => {
    setOpen(true);
    handleSelectPaymentToPay(paymentId);
  };

  const handleOk = async () => {
    await savePaymentInfo();
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setOpen(false);
    resetModal();
  };

  const resetModal = () => {
    setMetodoPago("Tarjeta");
    setReferenciaPago(null);
    setSelectedPayment(null);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm({ closeDropdown: true });
  };

  const savePaymentInfo = async () => {
    var updatedPaymentoInfo = SelectedPayment;

    updatedPaymentoInfo.isPaid = true;
    updatedPaymentoInfo.stripeCustomerId = "Pago Manual";
    updatedPaymentoInfo.stripePaymentIntentId = '('+MetodoPago+') ' + ReferenciaPago;
    try {
      const response = await fetch(`https://kometaapi.azurewebsites.net/api/PagarReciboOffline/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPaymentoInfo)
      });

      if (response.status === 200) {
        message.success({
          content: 'Pago registrado correctamente',
          className: 'custom-class',
          style: {
            marginTop: '8vh',
          },
        });
      }
    } catch (error) {
      console.error('Error al guardar pago!', error);
      message.error({
        content: 'Error al guardar pago: ' + error,
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: 'Alumno',
      dataIndex: 'student',
      key: 'student',
      ...getColumnSearchProps('student'),
    },
    {
      title: 'Fecha de cargo',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text) => moment(text).format('DD/MM/YY'), // Format the date
    },
    {
      title: 'Plan',
      dataIndex: 'planName',
      key: 'planName',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => '$' + text.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    {
      title: 'Id Alumno',
      dataIndex: 'idAlumno',
      key: 'idAlumno',
    },
    {
      title: 'Id pago',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(record._id)}>Registrar pago</Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getSchoolPendingPayments();
  }, []);

  const getSchoolPendingPayments = async () => {
    console.info(props.SchoolData.idEscuela);
    await fetch('https://kometaapi.azurewebsites.net/api/PagosPendientesEscuela/' + props.SchoolData.idEscuela)
      .then((response) => response.json())
      .then((data) => {
        setPagosPendientes(data);
        console.info(data);
      })
      .finally(() => setDataLoading(false))
      .catch((error) => console.log(error));
  };

  const handleMetodoPagoChange = (e) => {
    setMetodoPago(e.target.value);
  };

  const handleReferenciaPagoChange = (e) => {
    setReferenciaPago(e.target.value);
  };

  const handleSelectPaymentToPay = (_id) => {
    setSelectedPayment(PagosPendientes.find(p => p._id === _id));
    console.log(PagosPendientes.find(p => p._id === _id));
  };

  return (
    <Container>
      {dataLoading ? (
        <Spin />
      ) : (
        <Card title={"Registrar pago fuera de sistema"}>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "auto" }}>
              <p style={{ marginBottom: 1 }}>Usuario firmado:</p>
              <p style={{ marginBottom: 20 }}>{props.perfilUsuario.nombre + ' (ID: ' + props.perfilUsuario.idMaestro + ')'}</p>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={PagosPendientes}
          />
        </Card>
      )}

      <Modal
        title="Registro manual de pagos"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Registrar"
        cancelText="Cancelar"
        width={800}
      >
        <p>Detalle del cobro</p>
        <div style={{ marginTop: 10, marginBottom: 20, width: "90%", backgroundColor: "lightgrey", padding: 10, margin: "0 auto", textAlign: "center" }}>
          <Input addonBefore={<span style={{ width: 120, display: "inline-block" }}>Alumno:</span>} value={SelectedPayment?.student} readOnly />
          <Input addonBefore={<span style={{ width: 120, display: "inline-block" }}>Plan: </span>} value={SelectedPayment?.planName} readOnly />
          <Input addonBefore={<span style={{ width: 120, display: "inline-block" }}>Cantidad</span>} value={'$' + SelectedPayment?.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} readOnly />
          <Input addonBefore={<span style={{ width: 120, display: "inline-block" }}>Fecha límite</span>} value={moment(SelectedPayment?.dueDate).format('DD/MM/YY')} readOnly />
        </div>

        <p style={{ marginTop: 20 }}>Forma de pago</p>
        <Radio.Group onChange={handleMetodoPagoChange} value={MetodoPago}>
          <Space direction="horizontal" style={{ marginLeft: 50 }}>
            <Radio value={"Tarjeta"}>Pago con tarjeta</Radio>
            <Radio value={"Efectivo"}>Pago en efectivo</Radio>
            <Input addonBefore="Referencia de pago" maxLength={20} onChange={handleReferenciaPagoChange} value={ReferenciaPago} />
          </Space>
        </Radio.Group>

        <div style={{ marginTop: 25, width: "70%", marginLeft: 50 }}>
          <Input addonBefore={<span style={{ width: 120, display: "inline-block" }}>Persona en caja:</span>} value={props.perfilUsuario.nombre} readOnly />
        </div>
      </Modal>
    </Container>
  );
}

export default PagosCash;
